import { multiply } from '@/utils/math';
import store from '../store';

export default function formatDisplayPricePrecision(displayPrice, currencyCode) {
  let precision = store.getters.currencyPrecision;
  let separator = store.getters.currencySeparator || ',';
  if (currencyCode) {
    const matched = store.getters.currencies.find(({ code }) => code === currencyCode);
    if (matched) {
      precision = matched.precision;
      separator = matched.separator;
    }
  }
  const decSeparator = separator === '.' ? ',' : '.';
  const _price = parseFloat(displayPrice, 10);
  const strPrice = (Math.ceil(multiply(_price, 10 ** precision)) / (10 ** precision))
    .toFixed(precision);

  const splitPrice = strPrice.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,').split('.');

  return splitPrice[0].replaceAll(',', separator)
      + (splitPrice[1] ? decSeparator + splitPrice[1] : '');
}
