import Vue from 'vue';
import { DialogState, ProfileGuideEnum } from '@/enums';
import { i18n } from '@/utils/i18n';
import ua from '@/utils/ua';
import { addNavigateListener, removeNavigateListener } from '@/utils/navigate-events';

import Guide from './guide.vue';

const GuideCtor = Vue.extend(Guide);

const GuideCreator = (store) => (type, params = null) => {
  // 客户端登录：直接调客户端接口
  if (type === ProfileGuideEnum.signin && ua.os.client) {
    return store.dispatch('getClientToken', {
      params: { is_force: true },
      config: { timeout: -1 },
    }).then(() => store.dispatch('fetchLoginStatus'))
      .then(() => store.dispatch('fetchUserInfo', {
        ignore_retry: true,
      }), (err) => err);
  }
  // 非客户端时，调网页登录或者其它处理
  const instance = new GuideCtor({
    el: document.createElement('div'),
    propsData: { type, params },
    store,
    i18n,
  });
  // 短暂延迟，用于触发动画
  setTimeout(() => { instance.show = true; });
  instance.promise = new Promise((resolve, reject) => {
    const onNavigate = () => {
      // 延迟一小会 避免登录后语言不同导致的路由触发关闭事件
      setTimeout(() => { instance.$emit('close', DialogState.close); });
    };
    addNavigateListener(onNavigate);
    instance.$on('close', (state) => {
      removeNavigateListener(onNavigate);
      instance.show = false;
      if (state === DialogState.confirm) resolve();
      else reject(state);
    });
  });
  return instance.promise;
};

export default GuideCreator;
