import { multiply } from '@/utils/math';
import store from '../store';

export default function formatDisplayPrice(displayPrice, basePrice) {
  const rate = store.getters.currencyRate;
  const precision = store.getters.currencyPrecision;
  let price;
  let { currencyCode } = store.getters;
  let { currencySymbol } = store.getters;
  if (displayPrice?.currency === currencyCode) {
    price = parseFloat(displayPrice.price);
    currencyCode = displayPrice.currency;
    currencySymbol = displayPrice.currency_symbol;
  } else {
    let _price = parseFloat(basePrice, 10);
    _price *= rate;
    price = Math.ceil(multiply(_price, 10 ** precision)) / (10 ** precision);
  }
  return {
    price: price.toFixed(precision),
    currency: currencyCode,
    currency_symbol: currencySymbol,
  };
}
