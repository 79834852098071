<template>
  <div class="c-nav__help-center">
    <div class="c-nav__help-center-inner">
      <span class="font16r-min">{{ $t("nav.help_center") }}</span>
      <w-icon class="icon" :size="16" name="icon_arrow_down" />
    </div>
    <div class="c-nav__help-center-popover">
      <ul class="c-nav__help-center-list">
        <li v-for="(item, index) of menus" :key="index">
          <router-link :to="$_localePath(item.to)" :target="item.to.name === 'Faq' ? '_blank' : ''">
            <span class="font15r">{{ item.title }}</span>
            <w-icon name="icon_arrow_right" :size="16" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LoginStatus } from '@/enums';

export default {
  computed: {
    ...mapGetters(['loginStatus']),
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    menus() {
      const list = [
        { to: { name: 'Faq' }, title: 'LootBar FAQs', log: 'page_c2c_faq_click' },
      ];

      if (this.isLogined) {
        list.push({
          to: { name: 'Account.Feedback' },
          title: this.$t('support.ticket_records'),
          log: 'page_c2c_feedback_click',
        });
      }

      return list;
    },
  },
};
</script>

<style lang="less">
.c-nav__help-center {
  position: relative;

  &:hover {
    color: var(--el-wf1);

    .c-nav__help-center-inner {
      svg {
        transform: rotate(180deg);
      }
    }

    .c-nav__help-center-popover {
      display: flex;
    }
  }

  .c-nav__help-center-inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    svg {
      margin-left: 4px;
      transition: all 0.15s ease;
    }
  }
}

.c-nav__help-center-popover {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  background: var(--nc-g3);
  border-radius: 0 0 var(--radius-4) var(--radius-4);
  box-shadow: var(--shadow-3);
  width: 240px;
  z-index: 100000;

  &::after {
    content: "";
    position: absolute;
    bottom: -48px;
    left: 0;
    right: 0;
    height: 48px;
    background: transparent;
    cursor: default;
  }

  .c-nav__help-center-list {
    width: 100%;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        color: var(--nc-white);

        &:hover,
        &:active {
          opacity: 1;
        }

        &:hover {
          background: #3d3f43;
        }

        &:active {
          background: #52545B;
        }

        span {
          flex: 1;
        }

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
