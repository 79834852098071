import store from '../store';

export default function formatPriceSeparator(price) {
  const strPrice = String(price);
  const separator = store.getters.currencySeparator || ',';
  const decSeparator = separator === '.' ? ',' : '.';

  const splitPrice = strPrice.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,').split('.');
  return splitPrice[0].replaceAll(',', separator)
      + (splitPrice[1] ? decSeparator + splitPrice[1] : '');
}
