import Vue from 'vue';
import store from '@/store';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import { asyncLoadComponent } from '@/plugins/utils';

let openIframe = null;

const OpenIframeInstance = async ({
  url,
  title,
} = {}) => {
  if (!openIframe) {
    try {
      openIframe = await asyncLoadComponent(() => import('./index.vue'));
    } catch (error) {
      return Promise.reject();
    }
  }
  const OpenIframeCtor = Vue.extend(openIframe);
  const inMobile = isMobile();
  const instance = new OpenIframeCtor({
    el: document.createElement('div'),
    store,
    i18n,
    propsData: {
      isMobile: inMobile,
    },
    data: {
      iframeUrl: url,
      title,
    },
  });
  instance.show = true;

  instance.promise = new Promise((resolve, reject) => {
    instance.$on('close', (payload = {}) => {
      instance.show = false;
      instance.$destroy();
      const { status, ...data } = payload;
      if (status) {
        resolve(data);
      }
      reject(data);
    });
  });

  return instance.promise;
};
OpenIframeInstance.install = (VueInstance) => {
  VueInstance.prototype.$openIframe = OpenIframeInstance;
};

export default OpenIframeInstance;
