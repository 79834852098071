import { mapGetters } from 'vuex';
import localStorage from '@/utils/local-storage';
import { LoginStatus } from '@/enums';
import { POINT_TASK_NEW_TIME } from '@/enums/userInfo';

let isLootbarPointTaskList = false;
let lootbarPointTaskListCb = [];

/**
 * 个人中心共用的状态和方法
 */
export default {
  data() {
    return {
      point_task_list: [],
    };
  },
  computed: {
    ...mapGetters(['loginStatus', 'pointTaskNewTime']),
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    isLoadPoint() {
      if (!this.isLogined) return false;

      return true;
    },
    isPointTaskNewTime() {
      const state = this.$_isTaskListState(this.point_task_list);
      if (!state) return false;

      if (this.pointTaskNewTime) return true;

      return false;
    },
  },
  mounted() {
    this.$_getPointTaskList();
  },
  methods: {
    $_changePointTaskNew() {
      localStorage.set(POINT_TASK_NEW_TIME, new Date().getTime());
      this.$store.dispatch('fetchMessageNotification');
    },
    $_getPointTaskList(cb) {
      if (!this.isLoadPoint) return;

      // 临时变量：lootbarPointTaskList 防止多次调用
      // 临时变量：lootbarPointTaskListCb 队列回调
      if (isLootbarPointTaskList) {
        if (!lootbarPointTaskListCb) lootbarPointTaskListCb = [];
        if (cb && cb instanceof Function) {
          lootbarPointTaskListCb.push(cb);
        } else {
          lootbarPointTaskListCb.push((list) => {
            this.point_task_list = list;
          });
        }
        return;
      }

      isLootbarPointTaskList = true;
      this.$store.dispatch('getPointTaskList').then((res) => {
        console.log('getPointTaskList', res);
        this.point_task_list = res?.items || [];

        if (lootbarPointTaskListCb && lootbarPointTaskListCb.length) {
          lootbarPointTaskListCb.forEach((item) => {
            if (item && item instanceof Function) {
              item(this.point_task_list);
            }
          });

          lootbarPointTaskListCb = [];
        }

        isLootbarPointTaskList = false;
      });
    },
    $_isTaskListState(list) {
      if (!list || !list.length) return false;

      let isTaskListState = false;
      list.forEach((item) => {
        if (
          item.state !== 1
          && item.state !== 2
          && item.new_time
          && item.new_time * 1000 < new Date().getTime()
        ) {
          isTaskListState = true;
        }
      });

      return isTaskListState;
    },
  },
};
