import Axios from '@/utils/axios.ext';
import localStorage from '@/utils/local-storage';
import native from '@/utils/native.bridge';
import api from '@/api';
import { LoginStatus, TextType } from '@/enums';
import { logger } from '@/plugins/logger';
import { isMobile } from '@/utils';
import ua from '@/utils/ua';
import { loadLanguageAsync } from '@/utils/i18n';
import SessionStorage from '@/utils/session-storage';
import ABTest from '@/utils/abtest/coupler/vue';
import { POINT_TASK_NEW_TIME } from '@/enums/userInfo';

const client = ua.os.client || {};

const DEFAULT_CURRENCY_INFO = {
  key: 'USD',
  label: 'USD',
  code: 'USD',
  symbol: '$',
  rate: 1,
  precision: 2,
  separator: ',',
};

const state = {
  loginStatus: LoginStatus.unknown,
  userInfo: null,
  assetBrief: null,
  notifications: null,
  announcementGame: '',
  announcement: null,
  countryInfo: null,
  loginFpToken: null,
  unloginFpToken: null,

  verify: null,

  text: localStorage.get('select-language') || TextType.en, // 全局文本

  languages: [{
    key: 'en',
    label: 'English',
  },
  {
    key: 'ja',
    label: '日本語',
  },
  {
    key: 'zh_Hant',
    label: '中文(繁體)',
  }],
  currencies: [{ ...DEFAULT_CURRENCY_INFO }],
  baseCurrency: { ...DEFAULT_CURRENCY_INFO },
  currency: { ...DEFAULT_CURRENCY_INFO },
  allAppList: null,
  hotAppList: null,
  point: 0,
  allImSeller: [],
  inuseCouponCnt: 0,
  pendingExpireCouponCnt: 0,
  marketBuyHistoryNew: null,
  pointTaskNewTime: false,
};

const getters = {
  point: (_state) => _state.point,
  loginStatus: (_state) => _state.loginStatus,
  userInfo: (_state) => _state.userInfo,
  assetBrief: (_state) => _state.assetBrief,
  notifications: (_state) => _state.notifications,
  announcementGame: (_state) => _state.announcementGame,
  announcement: (_state) => _state.announcement,
  verify: (_state) => _state.verify,
  languages: (_state) => _state.languages,
  countryInfo: (_state) => _state.countryInfo,
  userSubgame: (_state) => {
    if (!_state.userInfo) return '';
    const roles = _state.userInfo.app_roles;
    if (!roles || !roles.length || !roles[0].subgame_name) return '';
    return roles[0].subgame_name;
  },
  currencies: (_state) => _state.currencies,
  currency: (_state) => _state.currency,
  baseCurrency: (_state) => _state.baseCurrency,
  currencyName: (_state) => {
    if (!_state.currencies) return '';
    const matched = _state.currencies
      .find(({ key }) => key === _state.userInfo?.currency ?? _state.currency.code);
    if (!matched) return '';
    return matched.name;
  },
  currencyCode: (_state) => _state.userInfo?.currency
    ?? _state.currency.code,
  currencySymbol: (_state) => _state.userInfo?.currency_symbol
    ?? _state.currency.symbol,
  currencyRate: (_state) => _state.userInfo?.currency_rate_base_usd
    ?? _state.currency.rate,
  currencyPrecision: (_state) => _state.userInfo?.currency_precision
    ?? _state.currency.precision,
  currencySeparator: (_state) => _state.userInfo?.currency_separator
    ?? _state.currency.separator,
  textType: (_state) => {
    let locale;
    if (_state.userInfo && TextType[_state.userInfo.language]) {
      locale = _state.userInfo.language;
    } else {
      locale = _state.text;
    }
    localStorage.set('select-language', locale);
    return locale;
  },
  allImSeller: (_state) => _state.allImSeller,
  allAppList: (_state) => _state.allAppList,
  hotAppList: (_state) => _state.hotAppList,
  inuseCouponCnt: (_state) => _state.inuseCouponCnt,
  pendingExpireCouponCnt: (_state) => _state.pendingExpireCouponCnt,
  marketBuyHistoryNew: (_state) => _state.marketBuyHistoryNew,
  pointTaskNewTime: (_state) => _state.pointTaskNewTime,
};
const mutations = {
  SET_POINT(_state, point) {
    _state.point = point;
  },
  SET_LOGIN_STATUS(_state, loginStatus) {
    _state.loginStatus = loginStatus;
  },
  SET_USER_INFO(_state, userInfo) {
    _state.userInfo = userInfo;
  },
  SET_COUNTRY_INFO(_state, countryInfo) {
    _state.countryInfo = countryInfo;
  },
  UPDATE_USER_INFO(_state, userInfo) {
    _state.userInfo = { ..._state.userInfo, ...userInfo };
  },
  SET_NOTIFICATION(_state, notifications) {
    _state.notifications = notifications;
  },
  UPDATE_NOTIFICATION(_state, notifications) {
    _state.notifications = { ..._state.notifications, ...notifications };
  },
  SET_ANNOUNCEMENT_GAME(_state, announcementGame) {
    _state.announcementGame = announcementGame || '';
  },
  SET_ANNOUNCEMENT(_state, announcement) {
    _state.announcement = announcement || null;
  },
  CLEAR_ANNOUNCEMENT(_state) {
    _state.announcement = null;
  },
  SET_ASSET_BRIEF(_state, assetBrief) {
    _state.assetBrief = assetBrief;
  },
  SET_FP_TOKEN(_state, { token, isLogin }) {
    if (isLogin) {
      _state.loginFpToken = token;
    } else {
      _state.unloginFpToken = token;
    }
  },
  SET_VERIFY(_state, verifyInfo) {
    if (!verifyInfo) {
      _state.verify = null;
      return;
    }
    const { cert, allow_to_cert: canVerify } = verifyInfo;
    const verify = { ...cert, canVerify };
    _state.verify = verify;
  },
  SET_LANGUAGES(_state, languages) {
    _state.languages = languages
      .filter((language) => TextType[language.value])
      .map((language) => ({
        key: language.value,
        label: language.name,
      }));
  },
  SET_CURRENCIES(_state, currencies) {
    _state.currencies = currencies.map((item) => ({
      key: item.value,
      label: item.value,
      code: item.value,
      symbol: item.symbol,
      rate: item.rate,
      precision: item.precision,
      separator: item.separator,
      name: item.name,
    }));
  },
  SET_CURRENCY(_state, currency) {
    _state.currency = { ..._state.currency, ...currency };
    localStorage.set('select-currency', _state.currency.code);
  },
  SET_CURRENCY_CODE(_state, currencyCode) {
    const matched = _state.currencies.find(({ code }) => code === currencyCode);
    if (matched) {
      _state.currency = { ..._state.currency, ...matched };
      localStorage.set('select-currency', matched.code);
    }
  },
  SET_INIT_CURRENCY(_state, currencyCode) {
    _state.initCurrencyCode = currencyCode;
  },
  /**
     * 设置全局文案类型
     * @param {string} text 文案类型
     */
  SET_TEXT_TYPE(_state, text) {
    _state.text = text;
    if (_state.userInfo) {
      _state.userInfo.language = text;
    }
  },
  SET_ALL_APP_LIST(_state, allAppList) {
    _state.allAppList = allAppList;
  },
  SET_HOT_APP_LIST(_state, hotAppList) {
    _state.hotAppList = hotAppList;
  },
  SET_ALL_IM_SELLER(_state, allImSeller) {
    _state.allImSeller = allImSeller;
  },
  SET_INUSE_COUPON_CNT(_state, inuseCouponCnt) {
    _state.inuseCouponCnt = inuseCouponCnt;
  },
  SET_PENDING_EXPIRE_COUPON_CNT(_state, pendingExpireCouponCnt) {
    _state.pendingExpireCouponCnt = pendingExpireCouponCnt;
  },
  SET_MARKET_BUY_HISTORY_NEW(_state, marketBuyHistoryNew) {
    _state.marketBuyHistoryNew = marketBuyHistoryNew;
  },
  SET_POINT_TASK_NEW_TIME(_state, data) {
    const time = localStorage.get(POINT_TASK_NEW_TIME) || 0;
    let result = false;
    if (data && data > time && data < new Date().getTime()) {
      result = true;
    }
    _state.pointTaskNewTime = result;
  },
};
const actions = {
  async getIntegrateInitInfo({ commit, dispatch }) {
    let resp = null;
    if (client.version) {
      try {
        resp = await native('request_server_api', {
          path: '/integrate/init_info',
          method: 'GET',
          params: {},
        });
      } catch (err) { /** */ }
    }
    if (!resp) {
      resp = await Axios({
        method: 'get',
        url: api.getIntegrateInitInfo(),
        // 临时写死新字段，用于返回新场景的通知
        params: { grant_repurchase_coupon: true },
      });
    }
    const {
      // 登录态
      login_check: loginCheck,
      // 通用数据
      country_info: countryInfo,
      currency_info: currencyInfo,
      feedback_token: feedbackToken,
      language_info: languageInfo,
      // 登录用户数据
      account_info: userInfo,
      brief_asset: assetBrief,
      cert_info: certInfo,
      unread_badge: unreadBadge,
      all_im_seller: allImSeller,
    } = resp.data;
    const isLogin = loginCheck.is_login;
    const status = isLogin ? LoginStatus.logined : LoginStatus.unlogined;
    commit('SET_LOGIN_STATUS', status);

    commit('SET_COUNTRY_INFO', countryInfo);
    commit('SET_CURRENCIES', currencyInfo.items);
    commit('SET_FEEDBACK_TOKEN', feedbackToken.token);
    commit('SET_LANGUAGES', languageInfo.items);
    commit('SET_ALL_IM_SELLER', allImSeller);

    if (isLogin) {
      commit('SET_LOGIN_STATUS', LoginStatus.logined);
      commit('SET_USER_INFO', userInfo);
      commit('SET_CURRENCY_CODE', userInfo.currency);
      commit('SET_ASSET_BRIEF', assetBrief);
      commit('SET_VERIFY', certInfo);
      commit('SET_NOTIFICATION', unreadBadge);
      commit('SET_SUBMIT_INFO_COUNT', unreadBadge?.submit_info_count || 0);
      commit('SET_POINT', userInfo?.point || 0);
      dispatch('getFeedbackUnread');
      commit('SET_INUSE_COUPON_CNT', userInfo.inuse_coupon_cnt || 0);
      commit('SET_PENDING_EXPIRE_COUPON_CNT', userInfo.pending_expire_coupon_cnt || 0);
      logger.login(userInfo.uid);
      ABTest.setLayer('uid', userInfo.uid);
    } else {
      localStorage.remove('ps-token');
    }
  },
  /**
   * 从服务端获取用户信息
   */
  fetchUserInfo({ commit, dispatch }, { metadata } = {}) {
    return Axios({
      method: 'get',
      url: api.getAccountUserInfo(),
      metadata: metadata || {},
    }).then((resp) => {
      commit('SET_USER_INFO', resp.data);
      commit('SET_CURRENCY_CODE', resp.data.currency);
      commit('SET_POINT', resp.data?.point || 0);
      dispatch('updateLaunguage', { language: resp.data.language });
      dispatch('fetchAssetBrief');
      dispatch('fetchMessageNotification');
      dispatch('fetchKycState');
      dispatch('fetchFeedbackToken').then(() => {
        dispatch('getFeedbackUnread');
      });
      dispatch('fetchMarketBuyHistoryNew');
      logger.login(resp.data.uid);
      ABTest.setLayer('uid', resp.data.uid);
      return resp.data;
    }).catch(() => {});
  },
  /**
   * 从本地读缓存的用户信息
   * 若没有则从服务端获取
   */
  getUserInfo({ state: _state, dispatch }) {
    if (!_state.userInfo) {
      return dispatch('fetchUserInfo');
    }
    return _state.userInfo;
  },
  /**
   * 从服务端更新用户信息
   */
  updateUserInfo({ commit }) {
    return Axios({
      method: 'get',
      url: api.getAccountUserInfo(),
    }).then((resp) => {
      commit('SET_USER_INFO', resp.data);
      commit('SET_CURRENCY_CODE', resp.data.currency);
      return resp.data;
    }).catch(() => {});
  },
  /**
   * 获取资产概况
   */
  fetchAssetBrief({ commit }) {
    return Axios({
      method: 'get',
      url: api.getAccountAssetBrief(),
      metadata: { ignore_log: true },
    }).then((resp) => {
      commit('SET_ASSET_BRIEF', resp.data);
      return resp.data;
    });
  },
  /**
   * 获取当前用户登录状态
   */
  fetchLoginStatus({ commit }) {
    return Axios({
      method: 'get',
      url: api.getAccountLoginStatus(),
    }).then((resp) => {
      const { is_login: isLogin } = resp.data;
      const status = isLogin ? LoginStatus.logined : LoginStatus.unlogined;
      resp.data.state = status;
      commit('SET_LOGIN_STATUS', status);
      if (status !== LoginStatus.logined) localStorage.remove('ps-token');
      return resp.data;
    });
  },
  // 获取discord code码
  getAccountDiscordCode() {
    return Axios({
      method: 'get',
      url: api.getAccountDiscordCode(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取当前用户是否绑定邮箱
   * @param {string} email 邮箱·
   */
  loginEmailCheck(_, { email }) {
    return Axios({
      method: 'get',
      url: api.loginEmailCheck(),
      params: {
        email,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取store信息
   * @param {string} userid 用户id
   */
  getUserStoreInfo(_, { id }) {
    return Axios({
      method: 'get',
      url: api.getUserStoreInfo(),
      params: {
        user_id: id,
      },
    }).then((resp) => resp.data);
  },
  fetchFeedbackToken({ commit }) {
    return Axios({
      method: 'get',
      url: api.getFeedbackToken(),
    }).then((resp) => {
      const { token } = resp.data;
      commit('SET_FEEDBACK_TOKEN', token);
    });
  },
  fetchMessageNotification({ commit }) {
    return Axios({
      method: 'get',
      url: api.getMessageNotification(),
      // 临时写死新字段，用于返回新场景的通知
      params: { grant_repurchase_coupon: true },
      metadata: { ignore_log: true },
    }).then((resp) => {
      const notification = {
        updated_at: {},
        to_accept_offer_order: { pubg: 0, dota2: 0 },
        to_deliver_order: { pubg: 0, dota2: 0 },
        ...resp.data,
      };
      commit('SET_NOTIFICATION', notification);
      commit('SET_INUSE_COUPON_CNT', notification.inuse_coupon_cnt || 0);
      commit('SET_SUBMIT_INFO_COUNT', notification?.submit_info_count || 0);
      commit('SET_POINT_TASK_NEW_TIME', notification?.point_task_new_time * 1000 || 0);
    });
  },
  getMessageNotificationRead(_, { noticeType }) {
    return Axios({
      method: 'post',
      url: api.getMessageNotificationRead(),
      data: {
        notice_type: noticeType,
      },
    }).then((resp) => resp.data);
  },
  fetchMessageAnnouncement({ commit, rootState }, { game } = {}) {
    return Axios({
      method: 'get',
      url: api.getMessageAnnouncement(),
      params: { game: game || rootState.game },
      metadata: { ignore_log: true },
    }).then((resp) => {
      const allAnnouncement = localStorage.get('announcement') || [];
      const cur = new Date().getTime();
      const activeAnnouncement = allAnnouncement.filter(({ expired }) => cur < expired);

      localStorage.set('announcement', activeAnnouncement);

      const announcement = resp.data.marquee;
      const matched = activeAnnouncement.find(({ id }) => id === announcement.id);

      if (!matched) {
        commit('SET_ANNOUNCEMENT', announcement);
      } else {
        commit('CLEAR_ANNOUNCEMENT');
      }
    });
  },
  clearMessageAnnouncement({ commit, state: _state }) {
    const allAnnouncement = localStorage.get('announcement') || [];
    const cur = new Date().getTime();
    const activeAnnouncement = allAnnouncement.filter(({ expired }) => cur < expired);

    const { announcement } = _state;
    const matched = activeAnnouncement.find(({ id }) => id === announcement.id);

    if (!matched) {
      activeAnnouncement.push({
        id: announcement.id,
        expired: announcement.end_time * 1e3,
      });
    }

    localStorage.set('announcement', activeAnnouncement);
    commit('CLEAR_ANNOUNCEMENT');
  },
  fetchMessageBanner({ rootState }, { game } = {}) {
    return Axios({
      method: 'get',
      url: api.getMessageBanner(),
      params: { game: game || rootState.game },
    }).then((resp) => resp.data.banners);
  },
  /**
   * 获取Kyc认证结果
   */
  fetchKycState({ commit }) {
    return Axios({
      method: 'get',
      url: api.getKycState(),
      metadata: { ignore_log: true },
    }).then((resp) => {
      const verify = resp.data;
      commit('SET_VERIFY', verify);
      return verify;
    });
  },
  /**
   * Kyc认证获取跳转链接
   */
  postKycGetUrl() {
    return Axios({
      method: 'post',
      url: api.postKycGetUrl(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取Basisid 认证结果
   */
  // fetchBasisidState({ commit }) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getBasisidState(),
  //   }).then((resp) => {
  //     const { cert, allow_to_verify: canVerify } = resp.data;
  //     const verify = { ...cert, canVerify };
  //     commit('SET_VERIFY', verify);
  //     return verify;
  //   });
  // },
  /**
   * BasisID认证校验(前端收到event callback后调用)
   */
  // postBasisidVerify({ dispatch }, { userHash }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postBasisidVerify(),
  //     data: { user_hash: userHash },
  //   }).then((resp) => {
  //     dispatch('fetchBasisidState');
  //     return resp.data;
  //   });
  // },
  /**
   * 同意协议确认注册
   * @param {number} steamid 获取登录状态时返回的steamid
   */
  // postRegisterConfirm(_, { steamid }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAccountRegisterConfirm(),
  //     data: { steamid },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 给邮箱发送认证code
   * @param {string} email 邮箱地址
   */
  postEmailSendAuthcode(_, { email }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailSendAuthcode(),
      data: { email },
    });
  },
  /**
   * 更新账号的邮箱地址
   * @param {string} email 邮箱地址
   * @param {string} authcode 认证code
   */
  postEmailVerifyAuthcode(_, { email, authcode }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailVerifyAuthcode(),
      data: { email, auth_code: authcode },
    });
  },
  /**
   * 更新账号的交易链接
   * @param {string} tradeUrl 输入的交易链接
   */
  // postMarketSteamTradeUrl({ commit }, { tradeUrl }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postMarketSteamTradeUrl(),
  //     data: { trade_url: tradeUrl },
  //   }).then((resp) => {
  //     commit('UPDATE_USER_INFO', { trade_url: tradeUrl });
  //     return resp.data;
  //   });
  // },
  /**
   * 更新账号的Steam API key
   * @param {string} apikey 输入的API key
   */
  // postAccountSteamApiKey({ commit }, { apikey }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAccountSteamApiKey(),
  //     data: { api_key: apikey },
  //   }).then((resp) => {
  //     commit('UPDATE_USER_INFO', { steam_api_key_state: 2 });
  //     return resp.data;
  //   });
  // },
  /**
   * 退出登录
   */
  postAppLogout({ dispatch }) {
    return Axios({
      method: 'post',
      url: api.postAccountAppLogout(),
    }).then((resp) => {
      dispatch('appLogout');
      return resp.data;
    });
  },
  appLogout({ commit, dispatch }) {
    localStorage.remove('ps-token');
    commit('SET_USER_INFO', null);
    commit('SET_LOGIN_STATUS', LoginStatus.unlogined);
    commit('SET_VERIFY', null);
    commit('SET_NOTIFICATION', null);
    commit('SET_FEEDBACK_UNREAD', 0);
    commit('CLEAR_IM_CONVERSATIONS');
    commit('SET_SUBMIT_INFO_COUNT', 0);
    commit('SET_POINT', 0);
    commit('SET_INUSE_COUPON_CNT', 0);
    dispatch('fetchFeedbackToken');
    logger.logout();
  },
  // postAllowBuyerBargain(_, { allowBargain }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAllowBuyerBargain(),
  //     data: { allow_buyer_bargain: allowBargain },
  //   }).then((resp) => resp.data);
  // },
  postAllowStoreDisplay(_, { allowDisplay }) {
    return Axios({
      method: 'post',
      url: api.postAllowStoreDisplay(),
      data: { allow_shop_display: allowDisplay },
    }).then((resp) => resp.data);
  },
  /**
 * 获取消息列表
 * @param {string} type trade: 交易消息 system: 系统消息 social: 社区消息
 */
  getMessages({ commit }, { type, page, ...payload }) {
    return Axios({
      method: 'get',
      url: api.getMessages(),
      params: {
        notice_type: type,
        page_num: page.pageNum,
        page_size: page.pageSize,
        ...payload,
      },
    }).then((resp) => {
      // const UnreadKeyEnum = {
      //   trade: 'unread_message',
      //   system: 'unread_system_message',
      //   social: 'unread_social_message',
      // };
      const UnreadKeyEnum = {
        1: 'unread_message',
        2: 'unread_system_message',
        3: 'unread_social_message',
      };
      commit('UPDATE_NOTIFICATION', { [UnreadKeyEnum[type]]: 0 });
      return resp.data;
    });
  },
  /**
   * 获取帮助中心标题
   */
  // getHelpInfo(_, { platform }) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getHelpInfo(),
  //     params: {
  //       platform,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取帮助中心标题
   * @param {number} platform 文本类型
   */
  getKnivesoutHelpInfo(_, { platform }) {
    return import('./helpInfo.json').then((obj) => obj[platform]);
    // return Axios({
    //   method: 'get',
    //   url: api.getKnivesoutHelpInfo(),
    //   params: {
    //     platform,
    //   },
    // }).then((resp) => resp.data);
  },
  /**
   * 获取帮助中心详情
   * @param {number} platform 文本类型
   * @param {string} slug 详情id
   */
  getHelpInfoDetail(_, { platform, slug }) {
    return import('./helpDetail.json').then((obj) => obj[platform][slug]);
    // return Axios({
    //   method: 'get',
    //   url: api.getHelpInfoDetail(),
    //   params: {
    //     platform,
    //     slug,
    //   },
    // }).then((resp) => resp.data);
  },
  /**
   * 修改昵称
   * @param {string} nickname 昵称
   */
  postChangeNickname(_, { nickname }) {
    return Axios({
      method: 'post',
      url: api.postChangeNickname(),
      data: { nickname },
    }).then((resp) => resp.data);
  },
  /**
   * 是否允许邮件通知
   * @param {boolean} allowEmail
   */
  // postAllowEmail(_, { allowEmail }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAllowEmail(),
  //     data: { allow_mail_notification: allowEmail },
  //   }).then((resp) => resp.data);
  // },
  /**
   * 更新账户敏感内容显示
   * @param {string} allowControl 是否控制显示
   */
  // postAllowSensitiveControl({ commit }, { allowControl }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postAllowSensitiveControl(),
  //     data: { allow_sensitive_content_control: allowControl },
  //   }).then((resp) => {
  //     commit('UPDATE_USER_INFO', { allow_sensitive_content_control: allowControl });
  //     return resp.data;
  //   });
  // },
  /**
   * 获取饰品关注列表
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   * @param {object} filter 饰品筛选参数
   */
  getBookMarkSellOrderList({ rootState }, {
    pageNum, pageSize, filter,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBookMarkSellOrderList(),
      params: {
        game: rootState.game,
        page_num: pageNum,
        page_size: pageSize,
        ...filter,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取收藏饰品列表
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   * @param {object} filter 饰品筛选参数
   */
  getBookMarkGoodsList({ rootState }, {
    pageNum, pageSize, filter,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBookMarkGoodsList(),
      params: {
        game: rootState.game,
        page_num: pageNum,
        page_size: pageSize,
        ...filter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取钱包交易历史
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   * @param {object} filter 饰品筛选参数
   */
  getBalanceFlowLog(_, { pageNum, pageSize, filter } = {}) {
    return Axios({
      method: 'get',
      url: api.getBalanceFlowLog(),
      params: {
        page_num: pageNum,
        page_size: pageSize,
        ...filter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取钱包充值历史
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getBalanceRechargeLog(_, { pageNum, pageSize } = {}) {
    return Axios({
      method: 'get',
      url: api.getBalanceRechargeLog(),
      params: {
        page_num: pageNum,
        page_size: pageSize,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取钱包提现历史
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getBalanceWithdrawLog(_, { pageNum, pageSize } = {}) {
    return Axios({
      method: 'get',
      url: api.getBalanceWithdrawLog(),
      params: {
        page_num: pageNum,
        page_size: pageSize,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 充值预校验
   * @param {Number} amount 输入金额
   * @param {Number} pay_method 支付方法，13: paypal, 14: cnp, 16: checkout, 17: checkout_boleto
   * @param {Number} pay_type 支付类型
   */
  postRechargePreview(_, { amount, payMethod, payType }) {
    return Axios({
      method: 'post',
      url: api.postRechargePreview(),
      data: { amount: amount.toString(), pay_method: payMethod, pay_type: payType },
    }).then((resp) => resp.data);
  },
  /**
   * 充值手续费
   * @param {Number} amount 输入金额
   * @param {Number} pay_method 支付方法，13: paypal, 14: cnp, 16: checkout, 17: checkout_boleto
   * @param {Number} pay_type 支付类型
   */
  getRechargeFee(_, {
    amount, payMethod, payType, signal,
  }) {
    const config = {
      method: 'get',
      url: api.getRechargeFee(),
      params: { amount, pay_method: payMethod, pay_type: payType },
    };
    if (signal) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },
  getForterSite() {
    return Axios({
      method: 'get',
      url: api.getForterSite(),
    }).then((resp) => resp.data);
  },
  /**
   * PayPal网页充值
   * @param {Number} amount 输入金额
   */
  // postRechargePaypalPage(_, { amount }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postRechargePaypalPage(),
  //     data: { amount },
  //   }).then((resp) => resp.data);
  // },
  /**
   * cnp网页充值
   * @param {Number} amount 输入金额
   * @param {String} billInfoId 账单信息ID
   */
  // postRechargeCnpPage(_, { amount, billInfoId }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postRechargeCnpPage(),
  //     data: { amount, bill_info_id: billInfoId },
  //   }).then((resp) => resp.data);
  // },
  /**
   * checkout网页充值
   * @param {Number} amount 输入金额
   * @param {String} source_id 充值来源
   * @param {String} forter_token Forter验证token
   */
  postRechargeCheckoutPage(_, { amount, id, forter }) {
    return Axios({
      method: 'post',
      url: api.postRechargeCheckoutPage(),
      data: { amount: amount.toString(), source_id: id, forter_token: forter },
    }).then((resp) => resp.data);
  },
  /**
   * 充值手续费
   * @param {String} id 充值订单号，可以批量查询（以,分割订单号）
   */
  getRechargeStateCheck(_, { id }) {
    return Axios({
      method: 'post',
      url: api.getRechargeStateCheck(),
      data: { recharge_ids: id },
    }).then((resp) => resp.data);
  },
  /**
   * checkout充值绑卡
   * @param {String} checkoutInfo 银行卡checkout提供信息(含token)
   * @param {Object} billInfo 银行卡信息
   */
  postCheckoutSourceCreate(_, { checkoutInfo, billInfo }) {
    return Axios({
      method: 'post',
      url: api.postCheckoutSourceCreate(),
      data: { ...checkoutInfo, ...billInfo },
    }).then((resp) => resp.data);
  },
  /**
   * checkout充值绑卡
   * @param {String} id 充值来源ID
   */
  postCheckoutSourceDelete(_, { id }) {
    return Axios({
      method: 'post',
      url: api.postCheckoutSourceDelete(),
      data: { source_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * 获取提现相关信息
   * @param {String} id 本次绑卡记录ID
   */
  getCheckoutSourceDetail(_, { id }) {
    return Axios({
      method: 'post',
      url: api.getCheckoutSourceDetail(),
      data: { verify_id: id },
    }).then((resp) => resp.data);
  },
  getLLPayToken() {
    return Axios({
      method: 'get',
      url: api.getLLPayToken(),
    }).then((resp) => resp.data);
  },
  postLLPayRechargeRequest(_, { amount, cardToken, billInfo }) {
    return Axios({
      method: 'post',
      url: api.postLLPayRechargeRequest(),
      data: {
        amount: amount.toString(), card_token: cardToken, ...billInfo,
      },
    }).then((resp) => resp.data);
  },
  postLLPayKonbiniRechargeRequest(_, { amount }) {
    return Axios({
      method: 'post',
      url: api.postLLPayKonbiniRechargeRequest(),
      data: { amount: amount.toString() },
    }).then((resp) => resp.data);
  },
  postLLPayPayeasyRechargeRequest(_, { amount }) {
    return Axios({
      method: 'post',
      url: api.postLLPayPayeasyRechargeRequest(),
      data: { amount: amount.toString() },
    }).then((resp) => resp.data);
  },
  postPaymentwallRechargeRequest(_, { amount, payType }) {
    return Axios({
      method: 'post',
      url: api.postPaymentwallRechargeRequest(),
      data: {
        amount: amount.toString(), pay_type: payType,
      },
    }).then((resp) => resp.data);
  },
  getAdyenRechargePayMethods() {
    return Axios({
      method: 'get',
      url: api.getAdyenRechargePayMethods(),
    }).then((resp) => resp.data);
  },
  postAdyenRechargeRequest(_, { amount, billInfo, forter }) {
    return Axios({
      method: 'post',
      url: api.postAdyenRechargeRequest(),
      data: {
        ...billInfo,
        amount: amount.toString(),
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  postAdyenCardRechargeRequest(_, { amount, billInfo, forter }) {
    return Axios({
      method: 'post',
      url: api.postAdyenCardRechargeRequest(),
      data: {
        ...billInfo,
        amount: amount.toString(),
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  postAdyenCardSourceDelete(_, { id }) {
    return Axios({
      method: 'post',
      url: api.postAdyenCardSourceDelete(),
      data: { source_id: id },
    }).then((resp) => resp.data);
  },
  postAdyenMomoRechargeRequest(_, { amount }) {
    return Axios({
      method: 'post',
      url: api.postAdyenMomoRechargeRequest(),
      data: { amount: amount.toString() },
    }).then((resp) => resp.data);
  },
  postPaypalRechargeRequest(_, { amount, forter }) {
    return Axios({
      method: 'post',
      url: api.postPaypalRechargeRequest(),
      data: { amount: amount.toString(), forter_token: forter },
    }).then((resp) => resp.data);
  },
  /**
   * 获取提现相关信息
   */
  getAssetWithdrawInfo() {
    return Axios({
      method: 'get',
      url: api.getAssetWithdrawInfo(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取提现手续费
   * @param {String} amount 提现金额
   * @param {Number} payMethod 提现方式
   * @param {Number} beneficiaryId 收款人ID
   */
  getAssetWithdrawFee(_, { amount, payMethod, beneficiaryId }) {
    return Axios({
      method: 'get',
      url: api.getAssetWithdrawFee(),
      params: {
        amount,
        pay_method: payMethod,
        beneficiary_id: beneficiaryId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取收款人信息收集Form表单
   * @param {string} category 表单类型 [address|account]
   * @param {string} bankCountry 银行国家代号
   * @param {string} bankId 银行ID
   * @param {string} accountCurrency 账号货币种类
   */
  getBeneficiaryFields(_, payload) {
    return Axios({
      method: 'get',
      url: api.getBeneficiaryFields(),
      params: payload,
    }).then((resp) => resp.data);
  },
  /**
   * 获取收款人信息收集Form表单
   * @param {string} beneficiaryId 收款人信息id
   */
  getUpdateBeneficiaryFields(_, { beneficiaryId }) {
    return Axios({
      method: 'get',
      url: api.getUpdateBeneficiaryFields(),
      params: { beneficiary_id: beneficiaryId },
    }).then((resp) => resp.data);
  },
  /**
   * 获取收款人信息收集Form表单 PayoneerCard
   * @param {string} category 表单类型 [address|account]
   * @param {string} bankCountry 银行国家代号
   * @param {string} bankId 银行ID
   * @param {string} accountCurrency 账号货币种类
   */
  getBeneficiaryFieldsPayoneer(_, payload) {
    return Axios({
      method: 'get',
      url: api.getBeneficiaryFieldsPayoneer(),
      params: payload,
    }).then((resp) => resp.data);
  },
  /**
   * 获取收款人信息收集Form表单 PayoneerCard
   * @param {string} beneficiaryId 收款人信息id
   */
  getUpdateBeneficiaryFieldsPayoneer(_, { beneficiaryId }) {
    return Axios({
      method: 'get',
      url: api.getUpdateBeneficiaryFieldsPayoneer(),
      params: { beneficiary_id: beneficiaryId },
    }).then((resp) => resp.data);
  },
  /**
   * 获取银行名称
   * @param {string} bankCountry 银行国家代号
   * @param {string} search 搜索银行名称
   * @param {number} pageNum 分页参数
   * @param {number} pageSize 分页参数
   */
  getBankList(_, {
    bankCountry, search, pageNum, pageSize,
  }) {
    return Axios({
      method: 'get',
      url: api.getBankList(),
      params: {
        bank_country: bankCountry,
        search,
        page_num: pageNum,
        page_size: pageSize,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取收款人信息列表
   */
  getBeneficiaryList() {
    return Axios({
      method: 'get',
      url: api.getBeneficiaryList(),
    }).then((resp) => resp.data);
  },
  /**
   * 创建收款人信息
   */
  postCreateBeneficiary(_, payload) {
    return Axios({
      method: 'post',
      url: api.postCreateBeneficiary(),
      data: payload,
    }).then((resp) => resp.data);
  },
  /**
   * 创建收款人信息
   */
  postUpdateBeneficiary(_, payload) {
    return Axios({
      method: 'post',
      url: api.postUpdateBeneficiary(),
      data: payload,
    }).then((resp) => resp.data);
  },
  /**
   * 创建收款人信息 Payoneer
   */
  postCreateBeneficiaryPayoneer(_, payload) {
    return Axios({
      method: 'post',
      url: api.postCreateBeneficiaryPayoneer(),
      data: { beneficiary_info: payload },
    }).then((resp) => resp.data);
  },
  /**
   * 创建收款人信息 Payoneer
   */
  postUpdateBeneficiaryPayoneer(_, payload) {
    const { beneficiary_id: beneficiaryId, ...rest } = payload;
    return Axios({
      method: 'post',
      url: api.postUpdateBeneficiaryPayoneer(),
      data: {
        beneficiary_id: beneficiaryId,
        beneficiary_info: rest,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 删除收款人信息
   * @param {String} method 删除的提现方式
   * @param {String} beneficiaryId 提现收款人id
   */
  postDeleteBeneficiary(_, { method, beneficiaryId }) {
    return Axios({
      method: 'post',
      url: api.postDeleteBeneficiary(method),
      data: { beneficiary_id: beneficiaryId },
    }).then((resp) => resp.data);
  },
  /**
   * 通过 Airwallex 提现到收款人
   * @param {String} amount 提现金额
   * @param {Number} beneficiaryId 提现收款人id
   */
  postWithdrawAirwallex(_, { amount, id }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawAirwallex(),
      data: { amount: amount.toString(), beneficiary_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * Payoneer账户提现
   * @param {String} amount 提现金额
   * @param {Number} beneficiaryId 提现收款人id
   */
  postWithdrawPayoneer(_, { amount, id }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawPayoneer(),
      data: { amount: amount.toString(), beneficiary_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * Payoneer银行卡提现
   * @param {String} amount 提现金额
   * @param {Number} beneficiaryId 提现收款人id
   */
  postWithdrawPayoneerCard(_, { amount, id }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawPayoneerCard(),
      data: { amount: amount.toString(), beneficiary_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * 获取登陆Payoneer账号的url
   * @param {Boolean} have_payoneer 是否已有Payoneer账户
   */
  getPayoneerLoginUrl(_, { havePayoneer }) {
    return Axios({
      method: 'post',
      url: api.getPayoneerLoginUrl(),
      data: { have_payoneer: havePayoneer },
    }).then((resp) => resp.data);
  },
  postWithdrawAmazonSendCode(_, { email }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawAmazonSendCode(),
      data: { email },
    }).then((resp) => resp.data);
  },
  postWithdrawAmazonCheck(_, { email, authcode, amount }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawAmazonCheck(),
      data: { email, auth_code: authcode, amount: amount.toString() },
    }).then((resp) => resp.data);
  },
  postWithdrawAmazon(_, { email, authcode, amount }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawAmazon(),
      data: { email, auth_code: authcode, amount: amount.toString() },
    }).then((resp) => resp.data);
  },
  postWithdrawPaypal(_, { amount, id }) {
    return Axios({
      method: 'post',
      url: api.postWithdrawPaypal(),
      data: { amount: amount.toString(), beneficiary_id: id },
    }).then((resp) => resp.data);
  },
  getWithdrawAmazonDetail(_, { id }) {
    return Axios({
      method: 'get',
      url: api.getWithdrawAmazonDetail(),
      params: { withdraw_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * 创建账单信息
   */
  // postCreateBillInfo(_, payload) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postCreateBillInfo(),
  //     data: payload,
  //   }).then((resp) => resp.data);
  // },
  /**
   * 获取充值信息
   */
  getRechargeInfo() {
    return Axios({
      method: 'get',
      url: api.getRechargeInfo(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取支付信息
   */
  getPayMethodList(_, {
    amount,
    sellOrderId,
    num,
    userCouponId,
    couponId,
    signal,
    point,
  }) {
    const config = {
      method: 'get',
      url: api.getPayMethodList(),
      params: {
        amount,
        sell_order_id: sellOrderId,
        num,
        user_coupon_id: userCouponId,
        coupon_id: couponId,
        use_point: point,
      },
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 获取支付信息
   */
  getPayMethodListCart(_, {
    sellOrders,
    userCouponId,
    couponId,
    signal,
    point,
  }) {
    const config = {
      method: 'post',
      url: api.getPayMethodListCart(),
      data: {
        sell_orders: sellOrders,
        user_coupon_id: userCouponId,
        coupon_id: couponId,
        use_point: point,
      },
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 使用 Paymentwall 直接支付
   */
  postPaymentwallPayRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postPaymentwallPayRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 Checkout 直接支付
   * @param {String} payOrderId 支付id
   * @param {String} forter_token Forter验证token
   */
  postCheckoutPayRequest(_, { payOrderId, forter }) {
    return Axios({
      method: 'post',
      url: api.postCheckoutPayRequest(),
      data: {
        pay_order_id: payOrderId,
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 llpayKoibini 直接支付
   * @param {String} payOrderId 支付id
   */
  postLLPayKoibiniRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postLLPayKoibiniRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
    * 使用 globalAlipay 直接支付
   */
  postGlobalAliPayRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postGlobalAliPayRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 Paypal 直接支付
   * @param {String} forter_token Forter验证token
   */
  postPayPalPayRequest(_, { payOrderId, forter }) {
    return Axios({
      method: 'post',
      url: api.postPayPalPayRequest(),
      data: {
        pay_order_id: payOrderId,
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 删除Paypal支付方式
   * @param {String} id 支付方式id
   */
  postPaypalSourceDelete(_, { id }) {
    return Axios({
      method: 'post',
      url: api.postPaypalSourceDelete(),
      data: { source_id: id },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 连连信用卡 直接支付
   */
  postLLPayCardRequest(_, { payOrderId, cardToken }) {
    return Axios({
      method: 'post',
      url: api.postLLPayCardRequest(),
      data: {
        pay_order_id: payOrderId,
        card_token: cardToken,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 连连信用卡 直接支付
   */
  postAdyenRequest(_, {
    payOrderId, cardInfo, paymentMethod, additionalInfo, forter,
  }) {
    return Axios({
      method: 'post',
      url: api.postAdyenRequest(),
      data: {
        pay_order_id: payOrderId,
        card_info: cardInfo,
        payment_method: paymentMethod,
        additional_info: additionalInfo,
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 连连信用卡 直接支付
   */
  postAdyenCardRequest(_, {
    payOrderId, paymentMethod, additionalInfo, forter,
  }) {
    return Axios({
      method: 'post',
      url: api.postAdyenCardRequest(),
      data: {
        pay_order_id: payOrderId,
        payment_method: paymentMethod,
        additional_info: additionalInfo,
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 连连信用卡 直接支付
   */
  postAdyenPaysafecardRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postAdyenPaysafecardRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 用于获取apply pay session
   */
  postApplePayPaymentSession(_, { url }) {
    return Axios({
      method: 'post',
      url: api.postApplePayPaymentSession(),
      data: {
        apple_url: url,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 apple pay 直接支付
   */
  postApplePayRequest(_, { payOrderId, forter }) {
    return Axios({
      method: 'post',
      url: api.postApplePayRequest(),
      data: {
        pay_order_id: payOrderId,
        forter_token: forter,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 ping pong card 支付
   */
  postPingPongCardRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postPingPongCardRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 ping pong qiwi 支付
   */
  postPingPongQiwiRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postPingPongQiwiRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 dukpay 支付
   */
  postDukpayRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postDukpayRequest(),
      data: {
        pay_order_id: payOrderId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 使用 tripleA 支付
   */
  postTripleAPayRequest(_, { payOrderId }) {
    return Axios({
      method: 'post',
      url: api.postTripleAPayRequest(),
      data: { pay_order_id: payOrderId },
    }).then((resp) => resp.data);
  },
  /**
   * 上报触发kyc的支付方式和金额
   */
  postRecordLimitedBuy(_, { payMethod, payType, price }) {
    return Axios({
      method: 'post',
      url: api.postRecordLimitedBuy(),
      data: {
        pay_method: payMethod,
        pay_type: payType,
        price,
      },
    }).then((resp) => resp.data);
  },
  /**
   * BasisID认证获取api form token
   */
  postBasisidToken() {
    return Axios({
      method: 'post',
      url: api.postBasisidToken(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取货币列表
   * @param {Boolean} withdraw 是否是提现
   */
  getSupportedCurrency(_, { withdraw } = {}) {
    const params = withdraw ? { withdraw: 1 } : {};
    return Axios({
      method: 'get',
      url: api.getSupportedCurrency(),
      params,
    }).then((resp) => resp.data);
  },
  /**
   * 设置BUFF价格货币
   * @param {String} currency 偏好货币
   */
  postPreferCurrency(_, { currency }) {
    return Axios({
      method: 'post',
      url: api.postPreferCurrency(),
      data: { currency },
    }).then((resp) => resp.data);
  },
  /**
   * 获取收藏店铺列表
   * @param {string} game 游戏
   * @param {number} page_num 分页参数
   * @param {number} page_size 分页参数
   */
  getAccountBookmarkShop({ rootState }, {
    pageNum, pageSize,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getAccountBookmarkShop(),
      params: {
        game: rootState.game,
        page_num: pageNum,
        page_size: pageSize,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取Boleto充值订单详情
   * @param {string} recharge_id 充值单号
   */
  // getBoletoRechargeDetail(_, { id }) {
  //   return Axios({
  //     method: 'get',
  //     url: api.getBoletoRechargeDetail(),
  //     params: {
  //       recharge_id: id,
  //     },
  //   }).then((resp) => resp.data);
  // },
  /**
   * Boleto网页充值
   * @param {Number} amount 输入金额
   * @param {String} name 姓名
   * @param {String} document 证件id
   * @param {String} email 电子邮箱
   */
  // postRechargeBoletoPage(_, {
  //   amount, name, document, email,
  // }) {
  //   return Axios({
  //     method: 'post',
  //     url: api.postRechargeBoletoPage(),
  //     data: {
  //       amount, name, document, email,
  //     },
  //   }).then((resp) => resp.data);
  // },

  /**
   * Razer网页充值
   * @param {Number} amount 输入金额
   * @param {Number} pay_method 支付方式
   */
  postRechargeRazerPage(_, {
    amount, method,
  }) {
    return Axios({
      method: 'post',
      url: api.postRechargeRazerPage(),
      data: {
        amount,
        pay_method: method,
      },
    }).then((resp) => resp.data);
  },

  postAccountPendingLogin(_, { loginType }) {
    return Axios({
      method: 'post',
      url: api.postAccountPendingLogin(),
      data: {
        login_type: loginType,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => resp.data);
  },

  postAccountEmailRegSend(_, { email, redirectUrl }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailRegSend(),
      data: { email, from_url: redirectUrl },
    }).then((resp) => resp.data);
  },

  getAccountEmailRegCheck(_, { email, code }) {
    return Axios({
      method: 'get',
      url: api.getAccountEmailRegCheck(),
      params: { email, code },
    }).then((resp) => resp.data);
  },

  postAccountEmailReg(_, { email, code, password }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailReg(),
      data: {
        email,
        code,
        password,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },

  postAccountEmailLogin(_, { email, password, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailLogin(),
      data: {
        email,
        password,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },

  postAccountEmailResetSend(_, { email, redirectUrl }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailResetSend(),
      data: { email, from_url: redirectUrl },
    }).then((resp) => resp.data);
  },

  getAccountEmailResetCheck(_, { email, code }) {
    return Axios({
      method: 'get',
      url: api.getAccountEmailResetCheck(),
      params: { email, code },
    }).then((resp) => resp.data);
  },

  postAccountEmailReset(_, { email, code, password }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailReset(),
      data: { email, code, password },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },

  postAccountEmailSetPassword(_, { password }) {
    return Axios({
      method: 'post',
      url: api.postAccountEmailSetPassword(),
      data: { password },
    }).then((resp) => resp.data);
  },

  /**
   * Facebook登录
   * @param {String} token 用户token
   * @param {String} retrieveToken 换绑token
   */
  postFacebookLogin(_, { token, retrieveToken, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postFacebookLogin(),
      data: {
        access_token: token,
        retrieve_token: retrieveToken,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Apple绑定
   * @param {String} idToken 用户token
   * @param {String} name 用户名称（拼接firstName和lastName）
   */
  postAppleBind(_, { idToken, name }) {
    return Axios({
      method: 'post',
      url: api.postAppleBind(),
      data: { id_token: idToken, name },
    }).then((resp) => resp.data);
  },
  /**
   * Apple登录
   * @param {String} idToken 用户token
   * @param {String} name 用户名称（拼接firstName和lastName）
   * @param {String} retrieveToken 换绑token
   */
  postAppleLogin(_, {
    idToken, name, retrieveToken, stayLogin,
  }) {
    return Axios({
      method: 'post',
      url: api.postAppleLogin(),
      data: {
        id_token: idToken,
        name,
        retrieve_token: retrieveToken,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Google登录
   * @param {String} token 用户token
   * @param {String} retrieveToken 换绑token
   */
  postGoogleLogin(_, { token, retrieveToken, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postGoogleLogin(),
      data: {
        access_token: token,
        retrieve_token: retrieveToken,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Google绑定
   * @param {String} payload
   */
  postGoogleBind(_, payload) {
    return Axios({
      method: 'post',
      url: api.postGoogleBind(),
      data: payload,
    }).then((resp) => resp.data);
  },
  /**
   * Facebook绑定
   * @param {String} access_token 用户token
   */
  postFacebookBind(_, { access_token: accessToken }) {
    return Axios({
      method: 'post',
      url: api.postFacebookBind(),
      data: {
        access_token: accessToken,
      },
    }).then((resp) => resp.data);
  },
  /**
   * Tiktok绑定
   * @param {String} code 用户code
   * @param {String} redirect_uri 重定向url
   */
  postTiktokBind(_, { code, redirect_uri: redirectUri }) {
    return Axios({
      method: 'post',
      url: api.postTiktokBind(),
      data: {
        code,
        redirect_uri: redirectUri,
      },
    }).then((resp) => resp.data);
  },
  /**
   * Tiktok登录
   * @param {String} code 用户code
   * @param {String} redirect_uri 重定向url
   */
  postTiktokLogin(_, { code, redirect_uri: redirectUri, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postTikTokLogin(),
      data: {
        code,
        redirect_uri: redirectUri,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Twitter登录
   * @param {String} token 用户token
   * @param {String} retrieveToken 换绑token
   */
  postTwitterLogin(_, { token, retrieveToken, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postTwitterLogin(),
      data: {
        access_token: token,
        retrieve_token: retrieveToken,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Twitter绑定
   * @param {String} payload
   */
  postTwitterBind(_, payload) {
    return Axios({
      method: 'post',
      url: api.postTwitterBind(),
      data: payload,
    }).then((resp) => resp.data);
  },
  /**
   * Line登录
   * @param {String} token 用户token
   * @param {String} retrieveToken 换绑token
   */
  postLineLogin(_, { token, retrieveToken, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postLineLogin(),
      data: {
        access_token: token,
        retrieve_token: retrieveToken,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * Line绑定
   * @param {OBject} payload code/redirect_uri
   */
  postLineBind(_, payload) {
    return Axios({
      method: 'post',
      url: api.postLineBind(),
      data: payload,
    }).then((resp) => resp.data);
  },
  /**
   * Vk绑定
   * @param {String} silent_token 用户token
   * @param {String} uuid 客户端生成的uuid
   */
  postVkBind(_, { silent_token: silentToken, uuid }) {
    return Axios({
      method: 'post',
      url: api.postVkBind(),
      data: {
        silent_token: silentToken,
        uuid,
      },
    }).then((resp) => resp.data);
  },
  /**
   * Vk登录
   * @param {String} silent_token 用户token
   * @param {String} uuid 客户端生成的uuid
   */
  postVkLogin(_, { silent_token: silentToken, uuid, stayLogin }) {
    return Axios({
      method: 'post',
      url: api.postTikTokLogin(),
      data: {
        silent_token: silentToken,
        uuid,
        share_token: SessionStorage.get('SHARE_TOKEN') || undefined,
        stay_login: stayLogin,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },
  /**
   * 单次token登录
   * @param {String} token 用户token
   * @param {String} userId 用户id
   */
  postOneTimeTokenLogin(_, { token, userId }) {
    return Axios({
      method: 'post',
      url: api.postOneTimeTokenLogin(),
      data: { token, user_id: userId },
    }).then((resp) => {
      localStorage.set('ps-token', resp.data.token);
      return resp.data;
    });
  },

  /**
   * Steam绑定验证
   */
  getSteamBindVerify(_, params) {
    return Axios({
      method: 'get',
      url: api.getSteamBindVerify(),
      params: {
        ...params,
      },
    }).then((resp) => resp.data);
  },
  /**
   * Steam绑定确认
   * @param {String} steam_id SteamID
   */
  postSteamBindConfirm(_, { id }) {
    return Axios({
      method: 'post',
      url: api.postSteamBindConfirm(),
      data: {
        steam_id: id,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 第三方游戏角色绑定
   * @param {String} game 第三方游戏
   * @param {String} role_id 角色id
   * @param {String} token 游戏token
   * @param {String} subgameName 游戏服务器名
   */
  postPartnerRoleBind(_, {
    game, id, token, subgameName,
  }) {
    return Axios({
      method: 'post',
      url: api.postPartnerRoleBind(),
      data: {
        game,
        role_id: id,
        token,
        subgame_name: subgameName,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 第三方游戏角色解绑
   * @param {String} game 第三方游戏
   */
  postPartnerRoleUnbind({ rootState }, data = {}) {
    const { game } = data;
    return Axios({
      method: 'post',
      url: api.postPartnerRoleUnbind(),
      data: { game: rootState.game || game },
    }).then((resp) => resp.data);
  },

  /**
   * 第三方游戏角色解绑前检测
   * @param {String} game 第三方游戏
   */
  getPartnerRoleUnbindCheck({ rootState }, data = {}) {
    const { game } = data;
    return Axios({
      method: 'get',
      url: api.getPartnerRoleUnbindCheck(),
      params: { game: rootState.game || game },
    }).then((resp) => resp.data);
  },

  /**
   * 获取第三方动态验证码
   * @param {string} game 游戏
   */
  getPartnerDynamicCode(_, { game }) {
    return Axios({
      method: 'get',
      url: api.getPartnerDynamicCode(),
      params: {
        game,
      },
    }).then((resp) => resp.data);
  },

  /**
   * 获取支持语言
   */
  getSupportedLanguage() {
    return Axios({
      method: 'get',
      url: api.getSupportedLanguage(),
    }).then((resp) => resp.data);
  },
  /**
   * 设置偏好语言
   * @param {String} language 偏好语言
   */
  postPreferLanguage({ dispatch }, { language }) {
    return Axios({
      method: 'post',
      url: api.postPreferLanguage(),
      data: {
        language,
      },
    }).then((resp) => dispatch('updateLaunguage', { language })
      .then(() => resp.data));
  },
  updateLaunguage({ commit }, { language }) {
    commit('SET_TEXT_TYPE', language);
    return loadLanguageAsync(language);
  },
  fetchUserInfoByShareKey(_, { shareKey }) {
    return Axios({
      method: 'get',
      url: api.getAccountUserInfoByShareKey(),
      params: {
        share_bind_key: shareKey,
      },
    }).then((resp) => resp.data);
  },
  postPartnerRoleBindByShareKey({ rootState }, { shareKey }) {
    return Axios({
      method: 'post',
      url: api.postPartnerRoleBindByShareKey(),
      data: {
        game: rootState.game,
        share_bind_key: shareKey,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 根据IP获取用户国家二位编码
   */
  getCountryCode({ state: _state, commit }) {
    if (_state.countryInfo) {
      return Promise.resolve(_state.countryInfo);
    }
    return Axios({
      method: 'get',
      url: api.getCountryCode(),
    }).then((resp) => {
      commit('SET_COUNTRY_INFO', resp.data);
      return resp.data;
    });
  },
  // 提交解绑申请
  postUnBindApply(_, data) {
    return Axios({
      method: 'post',
      url: api.postUnBindApply(),
      data,
    }).then((resp) => resp.data);
  },
  sendEmailCode(_, data) {
    return Axios({
      method: 'post',
      url: api.sendEmailCode(),
      data: {
        game: 'main',
        ...data,
      },
    }).then((resp) => resp.data);
  },
  applyAccountRetrieve(_, data) {
    return Axios({
      method: 'post',
      url: api.applyAccountRetrieve(),
      data: {
        game: 'main',
        ...data,
      },
    }).then((resp) => resp.data);
  },
  denyAccountRetrieve(_, data) {
    return Axios({
      method: 'post',
      url: api.denyAccountRetrieve(),
      data: {
        game: 'main',
        ...data,
      },
    }).then((resp) => resp.data);
  },
  denyRoleRetrieve(_, data) {
    return Axios({
      method: 'post',
      url: api.denyRoleRetrieve(),
      data,
    }).then((resp) => resp.data);
  },

  getAccountEmail(_, params) {
    return Axios({
      method: 'get',
      url: api.getAccountEmail(),
      params,
    }).then((resp) => resp.data);
  },
  /* 从客户端获取token
   * @param {Object} params 参数
   * @param {Boolean} params.is_force 是否强制登录来获取客户端token
   */
  getClientToken(_, { params, config } = {}) {
    return native('get_token', params, config).then((token) => {
      localStorage.set('ps-token', token);
      return token;
    }, (err) => Promise.reject(err));
  },
  postAccountFcmRegistToken(_, { token }) {
    return Axios({
      method: 'post',
      url: api.postAccountFcmRegistToken(),
      data: {
        push_token: token,
        system_type: isMobile() ? 'mobile_web' : 'pc_web',
        device_id: localStorage.get('device-id'),
      },
    });
  },
  async getNavGameAllAppList({ commit }) {
    return Axios({
      method: 'get',
      url: api.getNavGameAppList(),
      params: {
        category: 'all',
      },
    }).then((resp) => {
      const { items = [] } = resp.data;
      commit('SET_ALL_APP_LIST', items);
    }).catch(() => {});
  },
  async getNavGameHotAppList({ commit }) {
    return Axios({
      method: 'get',
      url: api.getNavGameAppList(),
      params: {
        category: 'hot',
      },
    }).then((resp) => {
      const { items = [] } = resp.data;
      commit('SET_HOT_APP_LIST', items);
    }).catch(() => {});
  },
  getPromoteAffTop() {
    return Axios({
      method: 'get',
      url: api.getPromoteAffTop(),
    }).then((resp) => resp.data);
  },
  getPromoteAffStat() {
    return Axios({
      method: 'get',
      url: api.getPromoteAffStat(),
    }).then((resp) => resp.data);
  },
  postPromoteEnableAff() {
    return Axios({
      method: 'post',
      url: api.postPromoteEnableAff(),
    }).then((resp) => resp.data);
  },
  getPromoteAffOrderList(_, params) {
    return Axios({
      method: 'get',
      url: api.getPromoteAffOrderList(),
      params,
    }).then((resp) => resp.data);
  },
  getPointLog(_, params) {
    return Axios({
      method: 'get',
      url: api.getPointLog(),
      params,
    }).then((resp) => resp.data);
  },
  getPointGiftList(_, params) {
    return Axios({
      method: 'get',
      url: api.getPointGiftList(),
      params,
    }).then((resp) => resp.data);
  },
  postPointTaskSubmit(_, data) {
    return Axios({
      method: 'post',
      url: api.postPointTaskSubmit(),
      data,
    }).then((resp) => resp.data);
  },
  postPointExchange(_, data) {
    return Axios({
      method: 'post',
      url: api.postPointExchange(),
      data,
    }).then((resp) => resp.data);
  },
  getPromoteGetCustomAppService(_, params) {
    return Axios({
      method: 'get',
      url: api.getPromoteGetCustomAppService(),
      params,
    }).then((resp) => resp.data);
  },
  getPromoteGetActivity(_, params) {
    return Axios({
      method: 'get',
      url: api.getPromoteGetActivity(),
      params,
    }).then((resp) => resp.data);
  },
  postPromoteCustomAff(_, data) {
    return Axios({
      method: 'post',
      url: api.postPromoteCustomAff(),
      data,
    }).then((resp) => resp.data);
  },
  postPromoteJoinAffActivity(_, data) {
    return Axios({
      method: 'post',
      url: api.postPromoteJoinAffActivity(),
      data,
    }).then((resp) => resp.data);
  },
  getPromoteAffUserList(_, params) {
    return Axios({
      method: 'get',
      url: api.getPromoteAffUserList(),
      params,
    }).then((resp) => resp.data);
  },
  postTpGenInviteLink(_, data) {
    return Axios({
      method: 'post',
      url: api.postTpGenInviteLink(),
      data,
    }).then((resp) => resp.data);
  },
  /**
   * 获取用户最近一次购买记录
   */
  fetchMarketBuyHistoryNew({ commit, dispatch }) {
    return dispatch('getMarketBuyHistoryNew', {
      pageNum: 1,
      pageSize: 1,
      queryState: 11,
    }).then((data) => {
      const { items = [] } = data;
      let item = null;
      if (items && items.length) [item] = items;
      commit('SET_MARKET_BUY_HISTORY_NEW', item);
      return item;
    });
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
