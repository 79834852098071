<template>
  <transition name="slideup">
    <div
      v-if="show"
      class="p-toast-outer"
      :style="style">
      <div class="p-toast" :class="[type ? `p-toast--${type}` : '']">
        <w-icon
          v-if="icon"
          class="p-toast-icon"
          :name="icon" :size="16" />{{ text }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    text: String,
    type: String,
    offset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    style() {
      return {
        marginTop: `-${this.offset * 60}px`,
      };
    },
    icon() {
      const enums = {
        success: 'icon_tick',
        error: 'icon_wrong',
        info: 'icon_skinlist_nameicon',
      };
      return enums[this.type];
    },
  },
  watch: {
    show(val) {
      if (!val) {
        window.setTimeout(() => {
          document.body.removeChild(this.$el);
          this.$destroy();
        }, 200);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.p-toast {
  &-outer {
    pointer-events: none;
    user-select: none;
    font-size: 16px;
    color: @label_white;
    line-height: 24px;
    width: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999999;
    margin-top: 0;
    transition: all 0.2s linear;
  }
  pointer-events: auto;
  display: inline-block;
  margin: 0 32px;
  padding: 12px 24px;
  min-width: 200px;
  max-width: 500px;
  border-radius: 4px;
  background-color: fade(@tint_black, 80%);

  &-icon {
    margin-top: -4px;
    margin-right: 8px;
    vertical-align: middle;
  }

  &--error &-icon {
    color: @tint_error;
  }
  &--success &-icon {
    color: #ffffff;
  }
}
.slideup-enter-active {
  transition: all 0.2s linear;
}
.slideup-leave-active {
  transition: all 0.1s linear;
}
.slideup-enter /* .slideup-leave-active below version 2.1.8 */ {
  transform: translate(-50%, 50%);
  opacity: 0;
}
.slideup-leave-to {
  transform: translate(-50%, -65%);
  opacity: 0;
}
</style>
