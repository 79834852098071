import VueRouter from 'vue-router';
import store from '@/store';
import {
  LoginStatus, VerifyEmailStatus, ProfileGuideEnum, GameType,
  TextType, AlternativeLanguages, HreflangType, TextTypeHreflangMap,
} from '@/enums';
import { isGoogleBot, isStandalone } from '@/utils';
import bus from '@/utils/bus';
import { i18n } from '@/utils/i18n';
import { urlSearchQuerys, filterUtm } from '@/utils/url-search';
import Toast from '@/plugins/toast';
import Password from '@/plugins/password/creator';
import GuideCtor from '@/plugins/guide/creator';
import DialogCtor from '@/plugins/dialog/creator';
import LocalStorage from '@/utils/local-storage';
import SessionStorage from '@/utils/session-storage';
import manifestGenerator from '@/utils/manifest-generator';
import setMeta from '@/utils/set-meta';
// import setHreflang from '@/utils/set-hreflang';
import { MethodRouteMap, extractSchema } from '@/utils/webpush';
import { TASK, startupRunner } from '@/utils/startup';

const guide = GuideCtor(store);
const dialog = DialogCtor();
const searchParams = urlSearchQuerys(window.location.search);
//  Analytics 的参数，用于跟踪网站流量来源
let UTMPARAMS = filterUtm(searchParams);
if (searchParams.aff_short) {
  UTMPARAMS = {
    utm_source: 'Affiliate',
    utm_medium: 'Affiliate',
    utm_campaign: searchParams.aff_short,
  };
}
if (!Object.keys(UTMPARAMS).length
  && window.location.pathname.indexOf('/top-up/zenless-zone-zero') !== -1) {
  UTMPARAMS = { utm_campaign: 'zzz' };
}
if (Object.keys(UTMPARAMS).length > 0) {
  SessionStorage.set('UTM_PARAMS', UTMPARAMS);
}
/** share_token, 用于分享等推广
 * 1、search中获取
 * 2、pwa中如果无1中数据，则从localstore中获取
 */
const _isStandalone = isStandalone();
const SHARETOKEN = searchParams?.share_token || (_isStandalone ? LocalStorage.get('SHARE_TOKEN') : '') || '';
SessionStorage.set('SHARE_TOKEN', SHARETOKEN);
// 非pwa设置localstorage
if (!_isStandalone && SHARETOKEN) {
  LocalStorage.set('SHARE_TOKEN', SHARETOKEN);
}

manifestGenerator({
  utmSource: UTMPARAMS.utm_source,
  utmCampaign: UTMPARAMS.utm_campaign,
});

function removeQueryFromRoute(route, key) {
  const { query, ...other } = route;
  const _query = { ...query };
  delete _query[key];
  return { ...other, query: _query };
}
function addQueryToRoute(route, key, val) {
  const { query, ...other } = route;
  const _query = { ...query, [key]: val };
  return { ...other, query: _query };
}

function addQuerySToRoute(route, payload = {}) {
  const { query, ...other } = route;
  const _query = { ...query, ...payload };
  // 需要添加的query和已有的query信息一致，则不需要替换, 原路退回吧
  if (JSON.stringify(query) === JSON.stringify(_query)) {
    return route;
  }
  return { ...other, query: _query };
}
function setParamToRoute(route, key, val) {
  const { params, ...other } = route;
  const _params = { ...params, [key]: val };
  return { ...other, params: _params };
}

function addUTMParamsToRoute(route) {
  UTMPARAMS = SessionStorage.get('UTM_PARAMS') || {};
  let HASURM = Object.keys(UTMPARAMS).length > 0;
  // 写死zzz在没有utm的情况下自动添加utm
  if (!HASURM && route.path.indexOf('/top-up/zenless-zone-zero') !== -1) {
    UTMPARAMS = { utm_campaign: 'zzz' };
    SessionStorage.set('UTM_PARAMS', UTMPARAMS);
    HASURM = true;
  }
  // 添加utm_*等信息
  if (HASURM && !route.query.utm_source) {
    return addQuerySToRoute(route, UTMPARAMS);
  }
  return route;
}

const _replace = VueRouter.prototype.replace;
const _resolve = VueRouter.prototype.resolve;

// 针对路由 replace 的场景
// beforeEachHook 可能导致覆盖路由丢失的情况
VueRouter.prototype.replace = function replace(route) {
  let _route = _resolve.call(this, route).route;
  // 添加utm_*等信息
  _route = addUTMParamsToRoute(_route);

  return _replace.call(this, _route);
};

// eslint-disable-next-line import/prefer-default-export
export const beforeEachHook = async (to, from, next) => {
  console.log(to, from);
  // setHreflang(to.path);
  if (to.fullPath.indexOf('/market/all#') > -1) {
    next({ path: to.fullPath.replace('/market/all#', '') });
    return;
  }
  let newTo = to;
  let guideSignin;

  // 添加utm_*等信息
  newTo = addUTMParamsToRoute(newTo);

  // 判断提示Email验证状态
  if (to.query && to.query['email-verification']) {
    const { query } = to;
    const status = query['email-verification'];
    // 延迟防止动画卡顿
    window.setTimeout(() => {
      if (status === VerifyEmailStatus.verified) {
        Toast('success')('Email Verified');
      } else {
        Toast()('Email verification link has expired, Please try again');
      }
    }, 500);
    newTo = removeQueryFromRoute(newTo, 'email-verification');
  }

  // 判断引导登录
  if (to.query && to.query['guide-signin']) {
    guideSignin = true;
    newTo = removeQueryFromRoute(newTo, 'guide-signin');
  }

  // 荒野子站带有分享码: 用于荒野子站的绑游戏账号的逻辑
  // 引导登录并绑定角色
  if (to.query && to.query['share-key']) {
    store.commit('SET_SHARE_KEY', to.query['share-key']);
    newTo = removeQueryFromRoute(newTo, 'share-key');
  }

  if (to.query && to.query.aff_short) {
    newTo = removeQueryFromRoute(newTo, 'aff_short');
  }

  if (to.query && to.query.retrieve_token) {
    LocalStorage.remove('ps-token');
    LocalStorage.remove('login-channel');
    const token = to.query.retrieve_token;
    store.dispatch('getAccountEmail', { retrieve_token: token })
      .then(() => {
        dialog({
          title: i18n.t('global.notice'),
          content: i18n.t('account.change_bind_tips'),
          needCancelBtn: false,
          onConfirm: () => {
            guide(ProfileGuideEnum.signin, { retrieveToken: token });
          },
        });
      }).catch(() => {
        dialog({
          title: i18n.t('global.notice'),
          content: i18n.t('account.retrieve_expired'),
          needCancelBtn: false,
        });
      });
    newTo = removeQueryFromRoute(newTo, 'retrieve_token');
  }

  if (to.query && to.query.email_check) {
    const { email_check: check, email_code: code, email } = to.query;
    if (code && email && check) {
      store.commit('SET_DELAY_SHARE_TOKEN', true);
      startupRunner.register(TASK.EmailCheck, () => new Promise((resolve) => {
        if (check === 'register') {
          Password('register')({
            code,
            email,
            onSuccess: () => { resolve(); },
            onClose: () => {
              store.commit('SET_DELAY_SHARE_TOKEN', false);
              resolve();
            },
          });
        } else if (check === 'reset') {
          Password('reset')({
            code,
            email,
            onSuccess: () => { resolve(); },
            onClose: () => {
              store.commit('SET_DELAY_SHARE_TOKEN', false);
              resolve();
            },
          });
        } else {
          resolve();
        }
      }));
      newTo = removeQueryFromRoute(newTo, 'email_check');
      newTo = removeQueryFromRoute(newTo, 'email_code');
      newTo = removeQueryFromRoute(newTo, 'email');
    }
  }

  if (to.query && to.query.auth_token && to.query.user_id) {
    const { auth_token: authToken, user_id: userId } = to.query;
    try {
      await store.dispatch('postOneTimeTokenLogin', { token: authToken, userId });
    } catch (err) { console.error(err); }
    newTo = removeQueryFromRoute(newTo, 'auth_token');
    newTo = removeQueryFromRoute(newTo, 'user_id');
  }

  // 读取当前用户登录状态
  let { loginStatus } = store.state.Account;
  if (loginStatus === LoginStatus.unknown) {
    // 不强制登录,仅去获取客户端 token信息
    await store.dispatch('getClientToken', { params: { is_force: false } }).catch(() => {});
    // 登陆状态未知且没有token时，跳过接口验证登录态，直接设置为未登录
    if (!LocalStorage.get('ps-token')) {
      store.commit('SET_LOGIN_STATUS', LoginStatus.unlogined);
    } else {
      try {
        await store.dispatch('getIntegrateInitInfo');
      } catch (err) {
        store.commit('SET_LOGIN_STATUS', LoginStatus.unlogined);
      }
    }
  }
  ({ loginStatus } = store.state.Account);

  let queryTextType;
  if (to.query && to.query.lang) {
    if (to.query.lang === 'zh_Hant') {
      queryTextType = TextType.zh_Hant;
      store.commit('SET_INIT_CURRENCY', 'TWD');
    } else if (to.query.lang === 'ja') {
      queryTextType = TextType.ja;
      store.commit('SET_INIT_CURRENCY', 'JPY');
    } else {
      queryTextType = TextType.en;
      store.commit('SET_INIT_CURRENCY', 'USD');
    }
    newTo = removeQueryFromRoute(newTo, 'lang');
  }

  const hreflang = to.params?.hreflang || 'en';
  let hreflangTextType = HreflangType[hreflang] || TextType.en;
  if (loginStatus === LoginStatus.logined || _isStandalone) {
    if (hreflangTextType !== store.getters.textType) {
      newTo = setParamToRoute(newTo, 'hreflang', TextTypeHreflangMap[store.getters.textType]);
      hreflangTextType = store.getters.textType;
    }
  } else if (queryTextType) {
    if (queryTextType !== hreflangTextType) {
      newTo = setParamToRoute(newTo, 'hreflang', TextTypeHreflangMap[queryTextType]);
      hreflangTextType = queryTextType;
    }
  }
  store.dispatch('updateLaunguage', { language: hreflangTextType });

  if (newTo.name === 'Topup') {
    const { game, hreflang: _hreflang } = newTo.params || {};
    if (game === 'genshin-impact' && !_hreflang) {
      newTo = setParamToRoute(newTo, 'game', 'genshin-impact-top-up');
    } else if (game === 'genshin-impact-top-up' && !!_hreflang) {
      newTo = setParamToRoute(newTo, 'game', 'genshin-impact');
    }
    if (game === 'free-fire' && !_hreflang) {
      newTo = setParamToRoute(newTo, 'game', 'free-fire-top-up');
    } else if (game === 'free-fire-top-up' && !!_hreflang) {
      newTo = setParamToRoute(newTo, 'game', 'free-fire');
    }
    if (game === 'pokemon-tgc-pocket') {
      newTo = setParamToRoute(newTo, 'game', 'pokemon-tcg-pocket');
    }
  }

  if (newTo.name === 'Coin') {
    const { game } = newTo.params || {};
    if (game === 'throne-and-liberty' || game === 'throne-liberty-lucent') {
      newTo = setParamToRoute(newTo, 'game', 'throne-and-liberty-lucent');
    }
  }

  if (to.name.startsWith('Market.')) {
    setMeta({}, { isMarket: true, language: hreflangTextType }, to.path);
  } else if (!isGoogleBot()) {
    setMeta({}, { isMarket: false, language: hreflangTextType }, to.path);
  }

  if (to.query && to.query.push) {
    const action = decodeURIComponent(to.query.push);
    const { method, payload } = extractSchema(action);
    if (method && MethodRouteMap[method]) {
      const userType = loginStatus === LoginStatus.unlogined
        ? 0 : store.state.Account.userInfo.user_type;
      const route = MethodRouteMap[method](payload, userType);
      if (route) next(route);
    } else {
      removeQueryFromRoute(newTo, 'push');
    }
  }

  // 检查当前路由是否需要登录
  if (to.meta.loginRequired && loginStatus !== LoginStatus.logined) {
    const redirect = to.name.startsWith('Market.') && !from.name
      ? { name: 'Market.Index' } : from;
    // 客户端中则强制登录获取到token信息,若取消登录或者登录失败，回到首页. code:-1，表示非客户端
    // 如果访问链接含 guide-signin 则先引导登录
    // 不含 guide-signin 或 不登录 则停留在原页面/返回首页
    const [error] = await store.dispatch('getClientToken', {
      params: { is_force: true },
      config: { timeout: -1 },
    }).then(() => [null], (err) => [err]);
    if (error && 'code' in error && error.code !== -1) {
      next(from);
      return;
    }

    if (guideSignin) {
      try {
        await guide(ProfileGuideEnum.signin);
      } catch (err) {
        next(redirect);
        return;
      }
    } else {
      next(redirect);
      return;
    }
  }
  // Market才需要携带game或者subgame_name，lootbar不需要
  if (to.name && to.name.startsWith('Market.')) {
    const defaultGame = 'knivesout';
    // 全局携带game参数
    if (!GameType[to.query.game]) {
      store.state.game = store.state.game || defaultGame;
      newTo = addQueryToRoute(newTo, 'game', store.state.game || defaultGame);
      bus.$emit('nav.game.set', GameType[to.query.game]);
    } else if (GameType[to.query.game] && GameType[to.query.game] !== store.state.game) {
      store.commit('SET_GAME_TYPE', GameType[to.query.game]);
      store.commit('SET_SUBGAME_TYPE', '');
      bus.$emit('nav.game.set', GameType[to.query.game]);
    }

    const promises = [
      store.dispatch('getSupportedSubgames'),
      store.dispatch('getMarketTags'),
    ];
    try {
      await Promise.all(promises);
    } catch (err) { console.log(err); }

    if (!store.getters.subgames || !store.getters.subgames.length) {
      if (to.name === 'Market.Maintenance') {
        next(); return;
      }
      next({ name: 'Market.Maintenance' });
      return;
    }

    const isAvailSubgame = store.getters.subgames.find(({ key }) => key === to.query.subgame_name);
    const defaultSubgame = store.getters.subgames.find((subgame) => !!subgame.default);
    const defaultSubgameKey = defaultSubgame ? defaultSubgame.key : '';
    if (!store.getters.userSubgame) {
      if (!isAvailSubgame) {
        store.state.subgame = store.getters.subgame || defaultSubgameKey;
        newTo = addQueryToRoute(newTo, 'subgame_name', store.getters.subgame || defaultSubgameKey);
      } else if (store.state.subgame !== to.query.subgame_name) {
        store.state.subgame = to.query.subgame_name;
        newTo = addQueryToRoute(newTo, 'subgame_name', to.query.subgame_name);
      }
    } else {
      if (store.getters.userSubgame !== to.query.subgame_name) {
        if (to.query.subgame_name) {
          await dialog({
            title: i18n.t('global.notice'),
            content: i18n.t('notice.subgame_not_match'),
            needCancelBtn: false,
          });
        }
        newTo = addQueryToRoute(newTo, 'subgame_name', store.getters.subgame);
      }
    }
  } else {
    store.commit('SET_GAME_TYPE', '');
    store.commit('SET_SUBGAME_TYPE', '');
    bus.$emit('nav.game.set', '');
  }

  if (newTo === to) {
    // 未产生新的路由，继续
    next();
  } else if (
    // 完全相同的路由，使用replace，避免多一条history记录, 产生该次是因上一中断路由引起的。
    (newTo.fullPath === to.fullPath)
    && (JSON.stringify(newTo.query) === JSON.stringify(to.query))
    && (JSON.stringify(newTo.params) === JSON.stringify(to.params))) {
    next({ ...to, replace: true });
  } else {
    // 中断当前路由，产生新的路由，会多一条history记录
    // 注意：无法处理 replace 的情况
    // 如果需要处理 replace 的情况，需要抽离到重写 replace 方法中
    next(newTo);
  }
};

export const hreflangPrefix = `/:hreflang(${AlternativeLanguages.join('|')})?`;
