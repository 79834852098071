import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {

};

const getters = {

};
const mutations = {

};
const actions = {
  postShareActive({ rootState }) {
    return Axios({
      method: 'post',
      url: api.postShareActive(),
      data: {
        game: rootState.game,
      },
    }).then((resp) => resp.data);
  },
  getActivityDetail({ rootState }) {
    return Axios({
      method: 'get',
      url: api.getActivityDetail(),
      params: {
        game: rootState.game,
      },
    }).then((resp) => resp.data);
  },
  postVoteShop({ rootState }, data) {
    return Axios({
      method: 'post',
      url: api.postVoteShop(),
      data: {
        game: rootState.game,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  getShareRank({ rootState }) {
    return Axios({
      method: 'get',
      url: api.getShareRank(),
      params: {
        game: rootState.game,
      },
    }).then((resp) => resp.data);
  },
  getVoteLog({ rootState }, params) {
    return Axios({
      method: 'get',
      url: api.getVoteLog(),
      params: {
        game: rootState.game,
        ...params,
      },
    }).then((resp) => resp.data);
  },
  getVoteHistory({ rootState }, params) {
    return Axios({
      method: 'get',
      url: api.getVoteHistory(),
      params: {
        game: rootState.game,
        ...params,
      },
    }).then((resp) => resp.data);
  },
  getShareLog({ rootState }) {
    return Axios({
      method: 'get',
      url: api.getShareLog(),
      params: {
        game: rootState.game,
      },
    }).then((resp) => resp.data);
  },
  getShareList(_, { game, ...other } = {}) {
    return Axios({
      method: 'get',
      url: api.getShareList(),
      params: {
        game,
        ...other,
      },
    }).then((resp) => resp.data);
  },
  getShareDetail(_, { id } = {}) {
    return Axios({
      method: 'get',
      url: api.getShareDetail(),
      params: {
        activity_id: id,
      },
    }).then((resp) => resp.data);
  },
  getShareActivityLog(_, { id } = {}) {
    return Axios({
      method: 'get',
      url: api.getShareActivityLog(),
      params: {
        activity_id: id,
      },
    }).then((resp) => resp.data);
  },
  postShareToken(_, { game, ...other } = {}) {
    return Axios({
      method: 'post',
      url: api.postShareToken(),
      data: {
        game,
        ...other,
      },
    }).then((resp) => resp.data);
  },
  getRegCoupon(_, params = {}) {
    return Axios({
      method: 'get',
      url: api.getRegCoupon(),
      params,
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
