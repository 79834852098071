<template>
  <div id="app" :class="appStatus">
    <Nav>
      <!-- 受邀注册的用户提示获取优惠券, 未登录才可提示-->
      <ModalRegCopuon
        slot="GuidanceZone"
        :show-modal="hasModalRegCopuon"
        :in-pc="true"
        @setAppStatus="setAppStatus"
        @close="handleModalRegCouponClose"
        @hide="handleModalRegCouponHide" />
      <DialogCoupon
        :show="showRepurchaseDialogCoupon"
        :desc="dialogCouponRepurchase?.desc"
        :coupons="dialogCouponRepurchase?.coupons"
        :confirm-btn-text="$t('$coupon.check_coupons')"
        :hand-show="true"
        :extra="dialogCouponRepurchase?.extra"
        @confirm="handleGoCouponList"
        @cancel="handleCloseDialogCoupon"
        @close="handleCloseDialogCoupon" />
      <DialogCoupon
        :show="dialogCouponGame?.show"
        :desc="dialogCouponGame?.desc"
        :bonus-text="dialogCouponGame?.bonusText"
        :coupons="dialogCouponGame?.coupons"
        :coupon-bg="dialogCouponGame?.couponBg"
        :confirm-btn-text="$t('new_coupon.collet_coupon')"
        :hand-show="true"
        :extra="dialogCouponGame?.extra"
        @confirm="handleConfirmDialogCouponGame"
        @cancel="dialogCouponGame.show = false"
        @close="dialogCouponGame.show = false" />
      <div v-if="announcement" class="announcement-wrapper">
        <div class="announcement">
          <GlobalNotice
            :content="announcement.message"
            :interactive="!!announcement.link"
            @content-click="handleContentClick"
            @close="handleCloseAnnouncement" />
        </div>
      </div>
      <transition name="fadein">
        <div v-if="suggestLanguage" class="nav-top-wrap">
          <div class="font18b nav-top-title">
            {{ suggestLanguage.title }}
            <w-button icon name="icon_close" :icon-size="24"
              @click="handleSuggestLanguageCancel" />
          </div>
          <div class="font16r nav-top-content">
            <p v-html="suggestLanguage.content"></p>
          </div>
          <div class="nav-top-actions">
            <w-button plain size="l"
              @click="handleSuggestLanguageCancel">
              {{ suggestLanguage.cancelText }}
            </w-button>
            <w-button type="primary" size="l"
              @click="handleSuggestLanguageConfirm">
              {{ suggestLanguage.confirmText }}
            </w-button>
          </div>
        </div>
      </transition>
      <transition name="fadein">
        <div v-if="!!updateExists" class="nav-top-wrap">
          <div class="font18b nav-top-title">
            {{ $t('global.update_prompt_title') }}
            <w-button icon name="icon_close" :icon-size="24"
              @click="cancelUpdate" />
          </div>
          <div class="font16r-min nav-top-content">
            <img src="~@/assets/images/logo.png" alt="LootBar logo" />
            <p>{{ $t('global.update_prompt_content') }}</p>
          </div>
          <div class="nav-top-actions">
            <w-button type="primary"
              @click="refreshApp">
              {{ $t('global.update_prompt_btn') }}
            </w-button>
          </div>
        </div>
      </transition>
    </Nav>
    <!-- <Widgets /> -->
    <div class="main" :class="{'has-announcement':announcement}">
      <router-view v-if="!reloading" />
    </div>
    <w-modal :show="showInstallCoupon">
      <div class="install-coupon-dialog">
        <w-button class="install-coupon-dialog-close"
          icon name="icon_close" size="m"
          @click="handleClaimInstallCouponClose" />
        <div class="font16 install-coupon-dialog-header">
          <p v-html="installCouponInfo?.title"></p>
        </div>
        <div class="font16 install-coupon-dialog-content">{{ installCouponInfo?.desc }}</div>
        <div class="install-coupon-dialog-action">
          <w-button
            type="primary"
            @click="handleInstallCouponConfirm(installCouponInfo?.routeName)">{{
            installCouponInfo?.btnText }}</w-button>
        </div>
      </div>
    </w-modal>
    <WidgetIm v-if="userInfo?.user_type !== 2" />
  </div>
</template>

<script>
import localStorage from '@/utils/local-storage';
import Nav from '@/components/Nav/Nav.desktop.c2c.vue';
import mixin from './App.mixin.c2c';

export default {
  components: { Nav },
  mixins: [mixin],
  mounted() {
    // 清除layout折叠
    localStorage.remove('layout-folded');
  },
};
</script>

<style lang="less" scoped>
.main {
  height: calc(100vh - 80px);
  &.has-announcement {
    padding-top: 44px;
  }
}

.announcement {
  .c2cPcMedia();
  &-wrapper {
    background-color: var(--bg-bg5);
  }
  :deep(.c-global-notice) {
    height: 44px;
    background-color: transparent;
    font-weight: 400;
    color: var(--el-gf1);
    &::before {
      width: 0;
    }
    &-content {
      padding-left: 0;
    }
    &-close {
      margin: 0 12px;
      &-button {
        color: var(--el-gf1)!important;
      }
    }
  }
}

.nav-top {
  &-wrap {
    position: absolute;
    right: 280px; top: 80px;
    max-width: 480px;
    min-width: 360px;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.20);
    background-color: @tint_white;
    z-index: 9;
  }
  &-title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-content {
    color: var(--el-gf2);
    display: flex;
    align-items: center;
    img {
      width: 64px; height: 64px;
      margin-right: 12px;
      flex: 0 0 auto;
    }
    :deep(span) {
      color: var(--fu-yellow2);
      font-weight: 600;
    }
  }
  &-actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    & > * {
      min-width: 140px;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.install-coupon-dialog {
  width: 320px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  &-close {
    position: absolute;
    left: 8px; top: 8px;
  }
  &-header {
    width: 320px; height: 180px;
    margin-top: -30px;
    padding: 56px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    background: center/contain url('~@/assets/images/install_coupon_dialog_bg.png') no-repeat;
    :deep(b) {
      color: @tint_error;
      font-weight: 500;
    }
  }
  &-content {
    margin: 20px 16px 12px;
    color: rgba(#1f2128, 0.6);
    text-align: center;
  }
  &-action {
    padding: 8px 16px 12px;
    &>* {
      width: 100%;
      height: 48px; line-height: 48px;
      font-size: 16px;
    }
  }
}
// pc端处于注册优惠券显示状态时
.inRegCoupon {
  :deep(.c-nav) {
    height: 80px + 44px;
  }
  :deep(.main) {
    height: calc(100vh - 80px - 44px);
  }
}
.desktop-cookie {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(#000, 0.8);
  &-wrap {
    padding: 16px;
    display: flex;
    justify-content: center;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
  }
  &-tips {

    .tips {
      line-height: 20px;
      color: #fcfcfc;
      margin-right: 16px;
      :deep(a) {
        white-space: nowrap;
        color: var(--el-bc0);
        text-decoration: underline;
      }
    }
  }
  &-btn {
    font-size: 16px;
    margin-right: 8px;
    align-self: end;
    height: 40px;
    line-height: 38px;
    min-width: unset;
    padding: 0 24px;
    &:last-child {
      font-weight: 600;
      margin-right: 0;
    }
  }
}
</style>
