import Vue from 'vue';
import { i18n } from '@/utils/i18n';
import ABTest from '@/utils/abtest/coupler/vue';

let Share = null;

const ShareCreator = (store) => async ({
  url = '',
  id = '',
  img = '',
  message = '',
  tip = '',
  posterImg = '',
  discount = false,
  extraParams = {},
  logPrefix = '',
  logParams = {},
  twitter = {},
  fixedTextType,
  shareButtonClick = () => {},
  ...more // 其他参数
} = {}) => {
  if (!Share) {
    try {
      // Share = await asyncLoadComponent(() => import('./share.vue'));
      Share = (await ABTest.asyncGetABTestComponent({
        experimentName: 'share_tip',
        variants: {
          legacy: () => import('./share.legacy.vue'),
          default: () => import('./share.new.vue'),
          new: () => import('./share.new.vue'),
        },
      })()).default;
    } catch (error) {
      return Promise.reject();
    }
  }
  const ShareCtor = Vue.extend(Share);
  const instance = new ShareCtor({
    el: document.createElement('div'),
    propsData: {
      url: (url || window.location.href), // 跳转链接
      id: `${id}`, // 模板id
      img, // 分享图片地址
      message, // 分享文案
      posterImg, // 海报背景图片地址
      tip, // 提示文案
      extraParams, // 扩展链接参数
      logPrefix, // 具体渠道埋点字段的前缀
      logParams, // 日志参数
      twitter, // 分享到twitter配置
      fixedTextType,
      discount,
      ...more, // 其他参数
    },
    store,
    i18n,
  });
  // 绑定当前的分享
  instance.$on('shareButtonClick', (params) => {
    shareButtonClick(params);
  });
  // instance.show = true;
  instance.promise = new Promise((resolve, reject) => {
    instance.$on('close', (state) => {
      instance.show = false;
      resolve({ state });
    });
    instance.$on('error', (error) => {
      instance.show = false;
      reject(error);
    });
  });

  instance.promise.instance = instance;

  return instance.promise;
};

export default ShareCreator;
