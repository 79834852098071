import LocalStorage from '@/utils/local-storage';

export const CMPHandlerStorageKey = 'CMP_HANDLER';

/**
 * 操作状态
 */
export const CMPHandlerEnums = {
  NORMAL: 'NORMAL',
  ACCEPTALL: 'ACCEPTALL',
  REJECTALL: 'REJECTALL',
  CONFIRMCHOICES: 'CONFIRMCHOICES',
};

export const CMPHandlerGetState = () => LocalStorage.get(CMPHandlerStorageKey);
export const CMPHandlerSetState = (val) => LocalStorage.set(CMPHandlerStorageKey, val);

export const CMPClientEventEnums = {
  ACCEPTALL: 1,
  REJECTALL: 2,
  CONFIRMCHOICES: 3,
};

export const CMPEventTypeEnums = {
  TEST: 'test',
  PRE: 'pre',
  PROD: 'prod',
};

export const CMPLoadBehaviorEnums = {
  NEW: 'new',
  EDIT: 'edit',
};

export const CMPOpenTypeEnums = {
  DEFAULT: 0,
  IFRAME: 1,
  NEWTAB: 2,
};

export const CMPErrorCodeEnums = {
  TIMEOUT: 'TIMEOUT',
  FILE: 'FILE',
  VARIABLE: 'VARIABLE',
  INIT: 'INIT',
  LOAD: 'LOAD',
  GETDATA: 'GETDATA',
  UPDATEDATA: 'UPDATEDATA',
  CLEAR: 'CLEAR',
};

function generateError(code, msg) {
  return {
    code,
    msg,
  };
}

/**
 * CMP 错误码
 */
const CMPError = {
  TIMEOUT: (e) => generateError(CMPErrorCodeEnums.TIMEOUT, e || 'File loading timeout'),
  FILE: (e) => generateError(CMPErrorCodeEnums.FILE, e || 'File loading failed'),
  VARIABLE: (e) => generateError(CMPErrorCodeEnums.VARIABLE, e || 'CMPHelper is not defined or invalid'),
  INIT: (e) => generateError(CMPErrorCodeEnums.INIT, e || 'init error'),
  LOAD: (e) => generateError(CMPErrorCodeEnums.LOAD, e || 'load error'),
  GETDATA: (e) => generateError(CMPErrorCodeEnums.GETDATA, e || 'get data error'),
  UPDATEDATA: (e) => generateError(CMPErrorCodeEnums.UPDATEDATA, e || 'update error'),
  CLEAR: (e) => generateError(CMPErrorCodeEnums.CLEAR, e || 'clear error'),
};

/**
 * 异步加载 JS 文件，支持重试和超时处理
 * @param {string} url - JS 文件的 URL
 * @param {number} retries - 最大重试次数，默认为 3
 * @param {number} timeout - 超时时间（毫秒），默认为 5000ms
 * @returns {Promise<void>}
 */
async function loadJSWithRetry(options = { url: '', retries: 3, timeout: 5000 }) {
  const url = options?.url || '';
  const timeout = options?.timeout || 5000;
  const retries = options?.retries || 3;

  const loadJS = () => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    const timeoutId = setTimeout(() => {
      document.head.removeChild(script);
      reject(new Error('File loading timeout'));
    }, timeout);

    script.onload = () => {
      clearTimeout(timeoutId);
      resolve();
    };

    script.onerror = () => {
      clearTimeout(timeoutId);
      document.head.removeChild(script);
      reject(new Error('File loading failed'));
    };

    document.head.appendChild(script);
  });

  const attempts = Array.from({ length: retries }, () => loadJS(url, timeout).catch((error) => {
    throw error;
  }));

  try {
    // 确保 attempts 数组不为空
    if (attempts.length === 0) {
      throw new Error('file not found');
    }

    // 尝试并行加载 JS 文件
    await Promise.any(attempts);
  } catch (error) {
    // 检查错误是否来自 Promise.any()
    if (error && error.errors && Array.isArray(error.errors)) {
      // 处理所有尝试都失败的情况
      throw new Error('No available loading attempts');
    } else {
      // 处理其他类型的错误
      throw error;
    }
  }
}

export function CMPClientEvent(e, handler) {
  switch (e.event) {
    case CMPClientEventEnums.ACCEPTALL:
      CMPHandlerSetState(CMPHandlerEnums.ACCEPTALL);
      if (handler?.accept) handler.accept();
      break;
    case CMPClientEventEnums.REJECTALL:
      CMPHandlerSetState(CMPHandlerEnums.REJECTALL);
      if (handler?.reject) handler.reject();
      break;
    case CMPClientEventEnums.CONFIRMCHOICES:
      CMPHandlerSetState(CMPHandlerEnums.CONFIRMCHOICES);
      if (handler?.choices) handler.choices(e.data);
      break;
    default:
      break;
  }
}

/**
 * CMP服务
 * - 对接文档：https://unisdk.nie.netease.com/doc/page/ngconsentmanager/zh/access-document/h5-access-documents
 * - demo：https://h5sdk-web-demo.unisdk.nie.netease.com/cmp
 */
export class CMPServer {
  cmp = null;

  // test
  // SDK = 'https://protocol-static-test.mpsdk.easebar.com/h5/cmp-sdk/1.0.2/cmp.umd.js';
  // prod
  //   SDK = 'https://protocol.unisdk.easebar.com/h5/cmp-sdk/1.0.1/cmp.umd.js';
  // lootbar
  SDK = 'https://cmp.lootbar.gg/h5/cmp-sdk/1.0.2/cmp.umd.js?v=2024110701';

  /**
   * 初始化CMP服务
   * type OptionType = {
   *    env?: {
   *        request?: 'test' | 'pre' | 'prod'
   *    }
   *    baseRequestUrl: 'https://cmp.lootbar.gg',
   *    handler?: {
   *        handleUpdate?: (data: CMPData) => void;
   *    }
   * }
   *
   * options
   * - load: 加载参数
   *   - retries: 重试次数，默认3次,
   *   - timeout：加载文件超时，默认5秒
   * - env: 环境，默认是测试环境
   * - handler: 回调函数
   *   - accept: 同意的回调
   *   - reject: 拒绝的回调
   *   - choices: 确认选择的回调
   */
  async init(options) {
    try {
      await loadJSWithRetry({
        url: this.SDK,
        ...options?.load,
      });
    } catch (e) {
      return Promise.reject(CMPError.LOAD(e));
    }

    return new Promise((resolve, reject) => {
      if (
        !window.CMPHelper
        || !(window.CMPHelper.getInstance && window.CMPHelper.getInstance instanceof Function)
      ) {
        reject(CMPError.VARIABLE());
        return;
      }

      this.cmp = window.CMPHelper.getInstance();

      const params = {
        env: {
          request: options?.env || CMPEventTypeEnums.PROD,
        },
        baseRequestUrl: 'https://cmp.lootbar.gg',
        handler: {
          handleUpdate(data) {
            if (data.client_data) CMPClientEvent(data.client_data, options.handler);
          },
        },
      };

      try {
        this.cmp.init(params);
        resolve();
      } catch (e) {
        reject(CMPError.INIT(e));
      }
    });
  }

  /**
   * 获取CMP链接
   * type OptionType = {
   *    behavior: 'new' | 'edit',
   *    openType: '0' | '1' | '2',
   *    searchParams: {
   *        data: configParams内容的base64编码
   *    }
   *    configParams: {
   *        gameid: 'lootbar',
            app_channel: 'lootbar',
            platform: 'w',
   *    },
   *    handler?: {
   *        handleUpdate?: (data: CMPData) => void;
   *    }
   * }
   *
   * options
   * - behavior: 只能为"new"或者"edit", 默认为"new", 使用"edit"时，会无视时间限制，使用目前已有的数据继续编辑
   * - openType: 触发cmp链接后的打开方式， 0-默认返回参数不打开url， 1-当前窗口iframe打开url， 2-新窗口打开url
   * - handler: 回调函数
   *   - show: 返回参数时回调，如果是设置了behavior: edit，则是展示弹窗后回调
   *   - complate: 如果没有在cmp区域，则直接返回
   */
  load(options) {
    // 用户已点击过确定/拒绝/选择全部，直接退出
    const state = CMPHandlerGetState();
    if (state && state !== CMPHandlerEnums.NORMAL) return Promise.resolve();

    return new Promise((resolve, reject) => {
      if (!this.cmp || !this.cmp.load) {
        reject(CMPError.VARIABLE());
        return;
      }

      const params = {
        behavior: options?.behavior || CMPLoadBehaviorEnums.NEW,
        openType: options?.openType || CMPOpenTypeEnums.IFRAME,
        searchParams: {
          data: 'eyJnYW1laWQiOiJsb290YmFyIiwiYXBwX2NoYW5uZWwiOiJsb290YmFyIiwicGxhdGZvcm0iOiJ3In0=',
        },
        configParams: {
          gameid: 'lootbar',
          app_channel: 'lootbar',
          platform: 'w',
        },
      };

      this.cmp
        .load(params)
        .then((data) => {
          if (!data.in_cmp_area) {
            // 如果没有在cmp区域，则直接返回
            if (options.handler?.complate) options.handler.complate(data);
            resolve(data);
            return;
          }

          if (data?.cmp_link) {
            if (options.handler?.show) options.handler.show(data);
          }

          resolve(data);
        })
        .catch((e) => {
          reject(CMPError.LOAD(e));
        });
    });
  }

  /**
   * 获取CMP数据
   */
  getData() {
    return new Promise((resolve, reject) => {
      if (!this.cmp || !this.cmp.getCMPData) {
        reject(CMPError.VARIABLE());
        return;
      }

      try {
        const data = this.cmp.getCMPData();
        resolve(data);
      } catch (e) {
        reject(CMPError.GETDATA(e));
      }
    });
  }

  /**
   * 更新CMP数据
   */
  updateData(options) {
    return new Promise((resolve, reject) => {
      if (!this.cmp || !this.cmp.updateOption) {
        reject(CMPError.VARIABLE());
        return;
      }

      const opt = {
        env: CMPEventTypeEnums.PROD,
        ...options,
      };

      try {
        this.cmp.updateOption({
          env: {
            request: opt.env,
          },
          handler: {
            handleUpdate(data) {
              if (data.client_data) CMPClientEvent(data.client_data, options.handler);
            },
          },
        });
        resolve();
      } catch (e) {
        reject(CMPError.UPDATEDATA(e));
      }
    });
  }

  /**
   * 清空CMP数据
   */
  clear() {
    return new Promise((resolve, reject) => {
      if (!this.cmp || !this.cmp.clear) {
        reject(CMPError.VARIABLE());
        return;
      }

      try {
        this.cmp.clear();
        resolve();
      } catch (e) {
        reject(CMPError.CLEAR(e));
      }
    });
  }
}

export default new CMPServer();
