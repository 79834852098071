import Axios from '@/utils/axios.ext';
import api from '@/api';
import SessionStorage from '@/utils/session-storage';

const state = {
};

const getters = {
};
const mutations = {
};
const actions = {
  /**
   * 购物车商品优惠价格计算
   * @param {string} sell_order_id 订单id
   * @param {string} price 购买金额
   */
  postGoodsBuyCartCalculate(_, {
    game, sell_orders: sellOrders = [], signal,
  } = {}) {
    const config = {
      method: 'post',
      url: api.postGoodsBuyCartCalculate(),
      data: {
        game,
        sell_orders: sellOrders,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    };
    if (config) {
      config.signal = signal;
    }
    return Axios(config).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
