<template>
  <div class="c-nav-userinfo">
    <router-link class="c-nav-userinfo-msg" :to="$_localePath({ name: 'Messages.Trade' })">
      <w-button class="c-nav-userinfo-msg-inner">
        <div
          v-if="notifications"
          style="display: flex; align-items: center; justify-content: center">
          <w-icon name="icon_mail_nav" :size="20" />
          <i v-if="unreadMessage" class="userinfo-unread-badge"></i>
        </div>
        <w-spinner v-else size="s" />
      </w-button>
    </router-link>
    <div :key="randomkey" class="c-nav-userinfo-avatar">
      <router-link
        :to="$_localePath({name:'Account'})"
        class="c-nav-userinfo-avatar-wrap">
        <Avatar v-if="userInfo" :src="userInfo.profile_pic" />
        <w-spinner v-else size="s" theme="normal-3" />
      </router-link>
      <span v-if="unreadTotal" class="font14b-min userinfo-unread-number">
        {{ unreadTotal }}
      </span>
      <span v-else-if="unreadCoupon" class="userinfo-unread-badge"></span>
      <div ref="floatingLayer" class="userinfo-popover w-popover__inner">
        <i class="popover-pad"></i>
        <div class="popover-row">
          <div class="popover-col">
            <router-link
              :to="$_localePath({ name: 'Account.MyBalance' })">
              <div v-if="assetBrief" class="popover-col-text font20b-min font-fin">
                {{ baseCurrencySymbol }}{{ assetBrief.cash_amount | formatUsd }}
              </div>
              <div class="popover-col-label font13r-min">{{ $t("account.my_balance") }}</div>
            </router-link>
          </div>
          <div class="popover-col">
            <router-link
              :to="$_localePath({ name: 'Account.MyPoint' })"
              @click.native="handlePointClick">
              <div v-if="assetBrief" class="popover-col-text font20b-min font-fin">
                <i class="icon-point"></i>{{ assetBrief.point }}
              </div>
              <div
                v-if="!isPointTaskNewTime && pointInfo && pointInfo.state === -1"
                class="popover-col-label font13r-min point">
                <w-icon name="icon_my_points" :size="12" /><span class="font12r-min">{{
                  $t("account.point_nav_check_in")
                }}</span>
              </div>
              <div v-else class="popover-col-label point-task-new-label">
                <span class="font13r-min">{{ $t("account.point_my") }}</span>
                <i v-if="isPointTaskNewTime"></i>
              </div>
            </router-link>
          </div>
        </div>
        <ul class="popover-menu">
          <router-link
            v-for="menu in menus"
            :key="menu.name"
            :to="$_localePath(menu.to)"
            :target="menu.to.name === 'Faq' ? '_blank' : ''"
            @click.native="handlePopoverMenu(menu)">
            <li style="position: relative">
              <w-icon v-if="menu.icon" :name="menu.icon" :size="24" class="menu-icon" />
              <div class="popover-menu-item-title font15r">
                {{ menu.title }}
                <div
                  v-if="menu.to.name === 'Account.Coupon' && pendingExpireCouponCnt"
                  class="item-coupon-tips font12r-min">
                  {{ $t("nav.expire_coupon", { count: pendingExpireCouponCnt }) }}
                </div>
                <div
                  v-if="menu.to.name === 'Account.AffiliateProgram' && affiliateProgramTips"
                  class="item-affiliate-tips font12r-min">
                  {{ affiliateProgramTips }}
                </div>
              </div>
              <span v-if="menu.to.name === 'Account.Coupon'" class="item-right">
                <div v-if="inuseCouponCnt" class="font14b-min c-nav-route-num">
                  {{ inuseCouponCnt }}
                </div>
                <div v-if="notifications?.grant_coupon?.total" class="font14b-min unread"></div>
              </span>
              <span
                v-if="menu.to.name === 'Account.Buy' && submitInfoCount"
                class="font14b-min c-nav-route-number">{{ submitInfoCount }}</span>
              <span
                v-if="menu.to.name === 'Account.Feedback' && feedbackUnread"
                style="text-align: right">
                <div class="font14b-min c-nav-route-number">{{ feedbackUnread }}</div></span>
              <w-icon name="icon_arrow_right" :size="16" />
            </li>
            <div v-if="menu.to.name === 'Account.Buy' && marketBuyHistoryNew" class="order-wait">
              <div class="wait-info">
                <div class="wait-name font12r">{{ assetName }}</div>
                <div class="wait-handler">
                  <w-button type="primary" @click.prevent="toCoin">{{
                    marketBuyHistoryNew.submit_btn_text ? marketBuyHistoryNew.submit_btn_text : $t("coin.submit_info")
                  }}
                  </w-button>
                </div>
              </div>
            </div>
          </router-link>
          <li class="popover-logout" :class="{ 'is-disabled': logoutLoading }" @click="handleLogout">
            <w-icon name="icon_menu_logout" :size="24" class="menu-icon" />
            <span>{{ $t("global.log_out") }}</span>
            <w-spinner v-if="logoutLoading" size="s" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MixinUserInfo from '@/mixins/userInfo';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'nav-user-info',
  components: { Avatar },
  mixins: [MixinUserInfo],
  data() {
    return {
      randomkey: 0, // 用于刷新用户头像及其hover弹窗
      isAvatar: false,
      logoutLoading: false,
      pointInfo: null,
      isPromoteActivity: false,
      promoteActivity: null,
      timers: [],
    };
  },
  computed: {
    ...mapGetters([
      'loginStatus',
      'userInfo',
      'assetBrief',
      'notifications',
      'submitInfoCount',
      'feedbackUnread',
      'inuseCouponCnt',
      'pendingExpireCouponCnt',
      'marketBuyHistoryNew',
    ]),
    unreadTradeMessage() {
      return this.notifications?.unread_message?.total ?? 0;
    },
    unreadSystemMessage() {
      return this.notifications?.unread_system_message?.total ?? 0;
    },
    unreadImMessage() {
      if (this.userInfo?.user_type === 2) return 0;
      return this.notifications?.unread_im_message?.total ?? 0;
    },
    unreadMessage() {
      if (!this.notifications) return false;
      return this.unreadTradeMessage + this.unreadSystemMessage + this.unreadImMessage > 0;
    },
    unreadCoupon() {
      return this.notifications?.grant_coupon?.total ?? 0;
    },
    unreadTotal() {
      return this.submitInfoCount + this.feedbackUnread;
    },
    unreadfeedbackMessage() {
      return !!this.feedbackUnread;
    },
    menus() {
      const uid = this.userInfo?.uid || '';
      const list = [
        {
          to: { name: 'Account.Buy', query: { tab: 'wait', needWait: true } },
          icon: 'icon_menu_order',
          title: this.$t('buy.history'),
          log: 'page_c2c_buyhistory_click',
        },
        {
          to: { name: 'Account.Coupon' },
          icon: 'icon_menu_coupon',
          title: this.$t('account.coupon'),
          log: 'page_coupons_cilck',
          logData: { uid },
        },
        {
          to: { name: 'Account.AccountSet' },
          icon: 'icon_menu_profile',
          title: this.$t('account.profile'),
          log: 'page_c2c_account_click',
        },
        {
          to: { name: 'Faq' },
          icon: 'icon_menu_help',
          title: this.$t('account.help'),
          log: 'page_c2c_faq_click',
        },
        {
          to: { name: 'Account.Feedback' },
          icon: 'icon_menu_feedback',
          title: this.$t('support.ticket_records'),
          tip: this.unreadfeedbackMessage,
          log: 'page_c2c_feedback_click',
        },
        {
          to: { name: 'Account.AffiliateProgram' },
          icon: 'icon_menu_affiliate',
          title: this.$t('account.affiliate_program'),
        },
      ];
      if (this.userInfo?.user_type === 2) {
        list.splice(2, 0, {
          to: { name: 'Seller' },
          title: this.$t('lootBar.seller_center'),
          log: 'page_c2c_sellercenter_click',
          logData: { uid },
        });
      }
      return list;
    },
    baseCurrencySymbol() {
      return this.$store.getters.baseCurrency.symbol;
    },
    affiliateProgramTips() {
      if (!this.isPromoteActivity) return '';

      if (!this.promoteActivity) return this.$t('account.affiliate_program_instruction');

      return this.promoteActivity.title;
    },
    assetName() {
      const isCustomCoin = this.marketBuyHistoryNew?.sku?.sku_type === 9;
      const isHaveShopCard = !!this.marketBuyHistoryNew?.cart_asset_info || isCustomCoin;
      let assetName = '';
      if (isHaveShopCard) {
        if (isCustomCoin) {
          assetName = this.marketBuyHistoryNew?.coin_cart_asset_info?.asset_name;
        } else {
          assetName = this.marketBuyHistoryNew?.cart_asset_info[0]?.asset_name;
        }
      } else {
        assetName = this.marketBuyHistoryNew?.sku?.info.asset_name;
      }
      return assetName;
    },
  },
  watch: {
    assetBrief() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
    this.$store.dispatch('fetchMarketBuyHistoryNew');
    document.addEventListener('click', this.handleOutsideClick);
    this.$store.dispatch('fetchMessageNotification');
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    this.timers.forEach((timer) => window.clearInterval(timer));
  },
  methods: {
    handlePopoverMenu(menu) {
      this.randomkey = Math.random();
      this.$logger(menu.log, menu?.logData);
    },
    handleOutsideClick(e) {
      if (this.$refs.floatingLayer && !this.$refs.floatingLayer.contains(e.target)) {
        this.isAvatar = false;
      }
    },
    async getPointTaskDetail() {
      const res = await this.$store.dispatch('getPointTaskDetail', { taskClassId: 1 });
      this.pointInfo = res;
    },
    async getPromoteGetActivity() {
      const res = await this.$store.dispatch('getPromoteGetActivity');
      this.promoteActivity = res?.activity;
      this.isPromoteActivity = true;
    },
    async handleLogout() {
      if (this.logoutLoading) return;
      this.$logger('log_out_click');
      this.logoutLoading = true;
      try {
        await this.$store.dispatch('postAppLogout');
      } catch (err) {
        this.$_handleError(err);
        this.logoutLoading = false;
        return;
      }
      this.logoutLoading = false;
    },
    toCoin() {
      this.$router.push({
        name: 'Account.Buy.Coin',
        params: { game: this?.marketBuyHistoryNew.game_app?.name, id: this?.marketBuyHistoryNew.id },
      });
    },
    refresh() {
      this.getPointTaskDetail();
      this.getPromoteGetActivity();
    },
    handlePointClick() {
      // pc个人中心页点击切换积分页时，清除new状态
      if (this.$route.name === 'Account.MyPoint.Task' && this.isPointTaskNewTime) {
        this.$_changePointTaskNew();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.c-nav-userinfo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 16px;

    @media (max-width: 1360px) {
      margin-right: 12px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
  .userinfo-unread {
    &-badge {
      position: absolute;
      z-index: 8001;
      right: 6px;
      top: 6px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: var(--fu-red1);
      box-sizing: border-box;
      pointer-events: none;
    }

    &-number {
      position: absolute;
      z-index: 8001;
      right: 0;
      top: 0;
      padding: 0 4px;
      border-radius: 8px;
      min-width: 16px;
      text-align: center;
      background-color: var(--fu-red1);
      color: var(--fu-white1);
      box-sizing: border-box;
      pointer-events: none;
    }
  }
  &-balance {
    height: 48px;
    padding: 4px 16px 0;
    box-sizing: border-box;
    background-color: fade(@tint_white, 5%);
    border-radius: 2px;
    color: @label_light_4!important;
    white-space: nowrap;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.3;
    }

    span {
      color: @label_primary_2;
    }
  }
  .c-nav-userinfo-msg {
    width: 44px;
    height: 44px;

    .c-nav-userinfo-msg-inner {
      background-color: fade(@tint_white, 5%);
      position: relative;
      z-index: 8000;
      min-width: auto;
      width: 44px;
      height: 44px;
      padding: 0;
      color: var(--nc-white);

      &:hover .userinfo-unread {
        border: 2px solid @tint_dark_4;
      }
    }
    .userinfo-unread {
      right: 9px;
      top: 12px;
    }
  }

  .c-nav-userinfo-avatar {
    position: relative;
    cursor: pointer;
    border-radius: 24px;
    user-select: none;

    &:hover {
      .userinfo-popover {
        display: block;
      }
    }

    .c-nav-userinfo-avatar-wrap {
      width: 40px;
      height: 40px;
      border-radius: 24px;
      border: 1px solid @tint_light_4;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      z-index: 8000;

      & > i {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        display: block;
        margin: -1px;
        background: center/contain no-repeat;
      }
    }

    .userinfo-unread-badge {
      top: 2px;
      right: 2px;
    }

    .userinfo-popover {
      display: none;
      background-color: var(--bg-bg1);
      position: absolute;
      z-index: 7999;
      top: 52px;
      right: 0;
      cursor: default;
      width: 296px;

      li:hover .userinfo-unread {
        border: 2px solid @tint_light_4;
      }

      .userinfo-unread {
        top: 4px;
        right: 4px;
      }

      .store-state {
        display: inline-block;
        border-radius: 2px;
        width: 47px;
        height: 18px;
        color: #ebebf5;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
      }

      .online {
        background: #7ed321;
      }

      .offline {
        background: #888888;
      }
    }
  }
}

.popover {
  &-pad {
    width: 100%;
    height: 64px;
    position: absolute;
    top: -64px;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }

  &-avatar {
    display: inline-block;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: center/contain no-repeat;
    margin-right: 12px;
  }

  &-menu {
    padding: 8px 4px;
    background-color: var(--bg-bg1);
    border-radius: 4px;

    a {
      white-space: nowrap;
      display: block;
      position: relative;
      margin-top: 2px;

      &:first-child {
        margin-top: 0;
      }

      span {
        margin-right: 4px;
      }

      & > .w-popover__inner {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
      }

      &:hover > .w-popover__inner {
        display: block;
      }

      &:hover {
        opacity: 1;
      }
    }

    li {
      display: flex;
      align-items: center;
      padding: 0 12px;
      height: 48px;
      border-radius: 4px;
      color: var(--nc-g2);
      cursor: pointer;

      &:hover {
        background-color: var(--nc-g10);
      }

      .popover-menu-item-content {
        color: @label_dark_4;
      }

      .c-nav-account-balance,
      .c-nav-account-point {
        color: var(--el-wf1);
      }

      .c-nav-account-point {
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
          width: 20px;
          height: 20px;
          background: url("@/assets/images/icon_point_logomini.png") no-repeat center;
          background-size: 100%;
        }
      }

      .popover-menu-item-title {
        flex: 1 1 0;

        .item-coupon-tips {
          color: var(--el-gf3);
        }

        .item-coupon-tips,
        .item-affiliate-tips {
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item-affiliate-tips {
          color: var(--fu-orange2);
        }
      }

      .item-right {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 4px;

        .unread {
          margin-left: 3px;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: var(--fu-red1);
          box-sizing: border-box;
          pointer-events: none;
        }
      }

      &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .menu-icon {
        margin-right: 12px;
      }
    }

    .buy-badge {
      margin-right: 0;
      width: 16px;
      height: 16px;
      text-align: center;
      color: var(--fu-white1);
      border-radius: 100%;
      background: var(--fu-red1);
    }

    .c-nav-route-number {
      display: inline-block;
      height: 16px;
      min-width: 16px;
      margin-left: 4px;
      padding: 0 4px;
      color: var(--fu-white1);
      background: var(--fu-red1);
      border-radius: 8px;
      text-align: center;
      box-sizing: border-box;
    }

    .order-wait {
      display: flex;
      align-items: center;
      margin: 2px 12px 0;
      border-bottom: 1px solid var(--bg-bg3);

      .wait-info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 264px;

        .wait-name {
          flex: 1;
          max-width: 184px;
          min-width: 0;
          color: var(--el-gf2);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .wait-handler {
          margin-left: 8px;
          padding: 6px 0;

          .w-button {
            padding: 0 8px;
            height: 24px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .popover-logout {
      margin-top: 2px;
    }
  }
}

.popover-row {
  display: flex;
  align-items: center;
  padding: 20px 0 16px;
  border-bottom: 1px solid var(--bg-bg3);

  .popover-col {
    flex: 1;
    border-left: 1px solid var(--bg-bg3);

    &:first-child {
      border-left: none;
    }

    .popover-col-text,
    .popover-col-label {
      text-align: center;
    }

    .popover-col-text {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--el-gf1);
    }

    .popover-col-label {
      margin-top: 4px;
      color: var(--el-gf2);

      &.point {
        display: flex;
        align-items: center;
        margin: 4px auto 0;
        padding: 0 8px;
        width: fit-content;
        max-width: 100px;
        height: 18px;
        border: 1px solid var(--fu-orange2);

        border-radius: 100px;

        svg {
          margin-right: 4px;
          color: var(--fu-orange2);
        }

        span {
          flex: 1;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--fu-orange2);
        }
      }

      &.point-task-new-label {
        display: flex;
        align-items: baseline;
        justify-content: center;

        i {
          margin-left: 1px;
          width: 26px;
          height: 12px;
          background: url("~@/assets/images/icon_tag_new.png") no-repeat center/contain;
        }
      }
    }

    .icon-point {
      margin-right: 4px;
      width: 16px;
      height: 16px;
      background: url("@/assets/images/icon_point_logomini.png") no-repeat center;
      background-size: 100%;
    }
  }
}
</style>
