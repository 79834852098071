import axios from 'axios';
import { logger } from '@/plugins/logger';
import throttle from './throttle';
import {
  isMobile, isiOS, isInBuffClient, isStandalone, isGoogleBot,
  guid,
} from '.';
import LocalStorage from './local-storage';
import cacheAdapter from './axions.extensions/cacheAdapter';
import retryAdapter from './axions.extensions/retryAdapter';

const _isMobile = isMobile();
const _isiOS = isiOS();
const _isInBuffClient = isInBuffClient();
const _isStandalone = isStandalone();
const _systemType = (() => {
  if (_isInBuffClient) {
    if (_isInBuffClient.ios) {
      return 'iOS';
    }
    return 'Android';
  }
  if (_isStandalone) {
    return _isMobile ? 'mobile_pwa' : 'pc_pwa';
  }
  return _isMobile ? 'mobile_web' : 'pc_web';
})();

const INCLUDESHEADER = [
  'X-PS-Locale',
  'X-Currency',
  'Authorization',
];

const instance = axios.create({
  adapter: retryAdapter(cacheAdapter(axios.defaults.adapter, {
    enabledByDefault: false,
    // header信息头缓存，用于适配多语言的接口，暂时只支持字符串
    defaultIncludeHeader: INCLUDESHEADER,
  })),
});

// 每1s聚合提交一次接口延迟数据
const addLog = (() => {
  let list = [];
  const log = throttle(() => {
    list.forEach((item) => {
      logger.log(item.type, item.data);
    });
    list = [];
  }, 1e3);
  return (type, data) => {
    list.push({ type, data });
    log();
  };
})();

if (!isGoogleBot()) {
  instance.defaults.timeout = 30 * 1e3;
}
instance.defaults.withCredentials = true;

instance.interceptors.request.use((req) => {
  req.metadata = { ...req.metadata, startTime: new Date() };
  const lang = LocalStorage.get('select-language');
  const currency = LocalStorage.get('select-currency');
  let uniqId = LocalStorage.get('ps-uniq-id');
  if (!uniqId) {
    uniqId = guid();
    LocalStorage.set('ps-uniq-id', uniqId);
  }
  let deviceId = LocalStorage.get('device-id');
  if (!deviceId) {
    deviceId = guid();
    LocalStorage.set('device-id', deviceId);
  }
  if (lang && !req.headers['X-PS-Locale']) req.headers['X-PS-Locale'] = lang;
  if (currency && !req.headers['X-Currency']) req.headers['X-Currency'] = currency;
  req.headers.Authorization = `PS ${LocalStorage.get('ps-token')}`;
  // 移动端添加新的header信息，区分osType
  if (_isMobile) {
    req.headers['X-PS-Os-Type'] = _isiOS ? 'iOS' : 'Android';
  }
  if (/^(\/[^/]*)?\/market\//.test(window.location.pathname)) {
    req.headers['Lootbar-Site-Name'] = 'knivesout';
  }
  req.headers['X-PS-System-Type'] = _systemType;
  req.headers['X-PS-Referer'] = window.location.href;
  req.headers['X-Uniq-Id'] = uniqId;
  req.headers['X-PS-Device-id'] = deviceId;
  return req;
}, (err) => Promise.reject(err));

instance.interceptors.response.use((res) => {
  if (res.config?.responseType) {
    return res;
  }
  if (res.config?.metadata) {
    res.config.metadata.endTime = new Date();
    res.duration = res.config.metadata.endTime - res.config.metadata.startTime;
    if (!res.config.metadata?.ignore_log) {
      const url = res.config.url.replace(/^https?:\/\/[^/]+/, '');
      // 采样率 0.05
      if (Math.random() < 0.05) {
        addLog('request_time_taken', { path: url, time: res.duration });
      }
    }
  }
  const uniqId = res.headers?.['x-uniq-id'] || res.headers?.['X-Uniq-Id'];
  if (uniqId) LocalStorage.set('ps-uniq-id', uniqId);
  // 检查是否需要主动刷新
  if (process.env.RELEASE_VERSION) {
    const map = LocalStorage.get('no-more-prompts') || {};
    const cur = new Date().getTime();
    if (!map.frontVersion || cur > map.frontVersion) {
      const frontVersion = res.headers?.['x-front-version'] || res.headers?.['X-Front-Version'];
      if (frontVersion > process.env.RELEASE_VERSION) {
        map.frontVersion = cur + 60 * 60 * 1e3; // 1小时后再次检查
        LocalStorage.set('no-more-prompts', map);
        window.location.reload();
      }
    }
  }
  const data = res.data || res;
  // data.code 为兼容旧接口
  const code = (data.status || data.code).toLowerCase();
  // 网络成功状态下的请求结果判断
  if (data && code === 'ok') {
    return data;
  }
  const errorData = {
    type: data && code === 'Realname Required' ? 'verify_error' : 'data_error',
    data,
    config: res.config,
  };
  return Promise.reject(errorData);
}, (error = {}) => {
  const { data, status, config } = error.response || {};
  const dataErrorStatus = [401, 409, 422];
  let errorData;
  if (dataErrorStatus.indexOf(status) !== -1) {
    let type;
    if (status === 422) type = 'paramater_error';
    else type = data && data.status === 'Realname Required' ? 'verify_error' : 'data_error';
    errorData = { type, data, config };
  } else {
    errorData = {
      type: error.code === 'ERR_CANCELED' ? 'canceled' : 'net_error',
      data: error,
    };
  }
  return Promise.reject(errorData);
});

export default instance;
