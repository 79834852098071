<template>
  <w-modal :show="show" :color="color">
    <div class="p-toast">
      <w-spinner size="m" theme="primary" />{{ text }}
    </div>
  </w-modal>
</template>

<script>
import { isMobile } from '@/utils';

export default {
  props: {
    text: String,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    color() {
      return !isMobile() ? 'transparent' : '';
    },
  },
};
</script>

<style lang="less" scoped>
.p-toast {
  pointer-events: auto;
  display: inline-flex;
  align-items: center;
  color: @label_white;
  font-size: 15px;
  margin: 0 32px;
  padding: 12px 24px;
  max-width: 500px;
  border-radius: 4px;
  background-color: fade(@tint_black, 80%);

  :deep(.w-spinner) {
    flex-shrink: 0;
    margin-right: 10px;
  }
  // &--loading {
  //   &::before {
  //     content: '';
  //     position: fixed;
  //     width: 200vw;
  //     height: 100vh;
  //     background: transparent;
  //     z-index: -1;
  //     left: -100vw;
  //   }
  // }
  // &-outer.is-mobile &--loading::before {
  //   height: var(--app-height);
  //   background: rgba(0, 0, 0, 0.6);
  // }
}
</style>
